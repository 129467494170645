import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, deleteDoc, addDoc, updateDoc, query, where } from 'firebase/firestore';
import { Table, Button } from 'antd';
import { db } from '../../../environment';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';

const PendingOrdersTableSTA = ({ setSelectedOrderId }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const MySwal = withReactContent(Swal);
  const userRole = localStorage.getItem('role'); // Ideally, fetch this more securely

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'pendingOrdersSTA'));
      const ordersData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setOrders(ordersData);
      setLoading(false);
    };

    fetchOrders();
  }, []);

  const handleApprove = async (order) => {
    const result = await MySwal.fire({
      title: 'Emin misiniz?',
      text: 'Seçilen siparişi onaylamak istediğinize emin misiniz?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet, onayla!',
      cancelButtonText: 'Hayır, iptal et!',
    });
  
    if (result.isConfirmed) {
      try {
        // Loop through each product (urunler) in the order to update stock amounts
        await Promise.all(order.urunler.map(async (urun) => {
          // Query the stock document based on urunKodu
          const stockQuery = query(collection(db, 'stocks'), where('FullStockCode', '==', urun.urunKodu));
          const stockSnapshot = await getDocs(stockQuery);
  
          if (!stockSnapshot.empty) {
            const stockDoc = stockSnapshot.docs[0]; // Get the first matched document
            const currentStock = parseFloat(stockDoc.data().UnitAmount) || 0; // Ensure it's a number
            const urunMiktar = parseFloat(urun.miktar) || 0; // Ensure it's a number
  
            const newStock = currentStock + urunMiktar; // Add the quantity from the approved order
  
            // Update the stock amount
            await updateDoc(stockDoc.ref, { UnitAmount: newStock });
          } else {
            throw new Error(`Stok bulunamadı: ${urun.urunAdi} (${urun.urunKodu})`);
          }
        }));
  
        // Add the order to the approved orders collection
        await addDoc(collection(db, 'approvedOrdersSTA'), order);
  
        // Delete the order from the pending orders collection
        await deleteDoc(doc(db, 'pendingOrdersSTA', order.id));
  
        // Send email notification via HTTP request
        await axios.post('https://us-central1-yucelkimyaerpdb.cloudfunctions.net/sendOrderApprovalEmail-sendOrderApprovalEmail', {
          email: order.formuHazirlayanEmail, // Assuming 'formuHazirlayanEmail' field contains the preparer's email
          orderNumber: order.belgeNo,
        });
  
        // Update the state to remove the approved order from the list
        setOrders(orders.filter(o => o.id !== order.id));
  
        // Show success message
        MySwal.fire({
          title: 'Sipariş Onaylandı!',
          icon: 'success',
          confirmButtonText: 'Tamam'
        });
      } catch (error) {
        console.error('Error approving order: ', error);
  
        // Show error message if something goes wrong
        MySwal.fire({
          title: 'Sipariş onaylanırken hata oluştu',
          icon: 'error',
          confirmButtonText: 'Tamam'
        });
      }
    }
  };
  
  
  

  const handleDelete = async (order) => {
    const result = await MySwal.fire({
      title: 'Emin misiniz?',
      text: 'Seçilen siparişi silmek istediğinize emin misiniz?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet, sil!',
      cancelButtonText: 'Hayır, iptal et!',
    });

    if (result.isConfirmed) {
      try {
        await deleteDoc(doc(db, 'pendingOrdersSTA', order.id));
        setOrders(orders.filter(o => o.id !== order.id));
        MySwal.fire({
          title: 'Sipariş Silindi!',
          icon: 'success',
          confirmButtonText: 'Tamam'
        });
      } catch (error) {
        console.error('Error deleting order: ', error);
        MySwal.fire({
          title: 'Sipariş silinirken hata oluştu',
          icon: 'error',
          confirmButtonText: 'Tamam'
        });
      }
    }
  };

  const handleRowClick = (record) => {
    setSelectedOrder(record);
    console.log("kayitt",record)
    setSelectedOrderId(record.id); // Pass selected order ID to parent
  };

  const columns = [
    { title: 'Belge No', dataIndex: 'belgeNo', key: 'belgeNo' },
    { title: 'Cari Kodu', dataIndex: 'cariKodu', key: 'cariKodu' },
    { title: 'Cari Adı', dataIndex: 'cariAdi', key: 'cariAdi' },
    { title: 'Sipariş Tarihi', dataIndex: 'siparisTarihi', key: 'siparisTarihi', render: date => date?.toDate().toLocaleDateString() },
    {
      title: ' ',
      key: 'action',
      render: (text, record) => (
        <div>
          {console.log("oaıfhg",userRole)}
          {userRole=="Satın Alma"? null:<Button type="primary" onClick={() => handleApprove(record)} style={{ marginRight: '10px' }}>
            Onayla
          </Button>}
          {userRole=="Satın Alma"?null:<Button type="default" onClick={() => handleDelete(record)}>
            Sil
          </Button>
          }
        </div>
      ),
    },
  ];

  return (
    <div className='mb-3'>
      <Table
        columns={columns}
        dataSource={orders}
        loading={loading}
        rowKey="id"
        rowClassName={(record) => (record.id === selectedOrder?.id ? 'selected-row' : '')}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
    </div>
  );
};

PendingOrdersTableSTA.propTypes = {
  setSelectedOrderId: PropTypes.func.isRequired,
};

export default PendingOrdersTableSTA;
