import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, DatePicker, Divider, message, Modal, List, Row, Col, Layout, Menu, Select, InputNumber } from "antd";
import { PlusOutlined, MinusCircleOutlined, HistoryOutlined, FileAddOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from "moment";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { collection, query, orderBy, limit, getDocs, addDoc, updateDoc, deleteDoc, doc, arrayUnion, getDoc, where, startAt } from "firebase/firestore";
import { db } from "../../environment";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';

const { Sider, Content } = Layout;
const { Search } = Input;
const { Option } = Select;

const ReceteOlustur = () => {
  const [form] = Form.useForm();
  const [recipeNumber, setRecipeNumber] = useState("");
  const [totalBatchKg, setTotalBatchKg] = useState(0);
  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentRecipeId, setCurrentRecipeId] = useState(null);
  const [recipes, setRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [warehouses, setWarehouses] = useState([]); // State for warehouses
  const [, setSelectedMaterials] = useState({});
  const MySwal = withReactContent(Swal);
  const user = localStorage.getItem('user').toString();

  // Fetch warehouse list from Firestore
  const fetchWarehouses = async () => {
    const warehouseSnapshot = await getDocs(collection(db, "depolar"));
    const warehouseList = warehouseSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setWarehouses(warehouseList);
  };

  // Fetch stocks based on the selected warehouse
  const fetchMaterialsByWarehouse = async (warehouseName) => {
    const stockQuery = query(
      collection(db, "stocks"),
      where("Warehouse", "==", warehouseName),
      where("StockCode", ">=", "150"),
      where("StockCode", "<=", "150\uf8ff")
    );
    
    const stockSnapshot = await getDocs(stockQuery);
    const stockList = stockSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setMaterials(stockList);
  };
  

  const fetchStocks = async () => {
    try {
      // Create a query that fetches only documents where FullStockCode starts with "152"
      const q = query(
        collection(db, "stocks"),
        orderBy("FullStockCode"),
        startAt("152"),
      );
  
      const stockSnapshot = await getDocs(q);
      
      // Get the data from Firestore
      const stocksData = stockSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      // Filter out documents where FullStockCode starts with "PK-"
      const filteredStocks = stocksData.filter(stock => !stock.FullStockCode.startsWith("157"));
  
      // Set the filtered stocks to state
      setStocks(filteredStocks);
    } catch (error) {
      console.error("Error fetching stocks: ", error);
    }
  };

  const fetchRecipes = async () => {
    const q = query(collection(db, "productRecipe"), orderBy("recipeNumber", "asc"));
    const snapshot = await getDocs(q);
    const recipeList = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setRecipes(recipeList);
    setFilteredRecipes(recipeList);
  };

  const fetchCategories = async () => {
    const q = query(collection(db, "stockManager"), orderBy("title", "asc"));
    const snapshot = await getDocs(q);
    const categoryList = snapshot.docs.map(doc => doc.data());
    setCategories(categoryList);
    if (categoryList.length > 0) {
      setSubCategories(categoryList[0].children ? categoryList[0].children : []);
      form.setFieldsValue({
        productCategory: categoryList[0].categoryCode,
        subCategory: categoryList[0].children ? categoryList[0].children[0].title : ""
      });
    }
  };



  useEffect(() => {
    const fetchLastRecipeNumber = async () => {
      const now = moment();
      const yearMonth = now.format('YYMM'); // Yılın son iki hanesi ve ay
      const prefix = `RCT/${yearMonth}-`;

      const q = query(collection(db, "productRecipe"), orderBy("recipeNumber", "desc"), limit(1));
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        const lastRecipe = snapshot.docs[0].data();
        const lastNumber = parseInt(lastRecipe.recipeNumber.split('-')[1]);
        const newRecipeNumber = `${prefix}${String(lastNumber + 1).padStart(3, '0')}`;
        setRecipeNumber(newRecipeNumber);
      } else {
        setRecipeNumber(`${prefix}${String(1).padStart(3, '0')}`);
      }
    };
    fetchStocks();
    fetchRecipes();
    fetchLastRecipeNumber();
    fetchCategories();
    fetchWarehouses(); // Fetch warehouses on component mount
  }, []);

  const handleMaterialChange = (value, key) => {
    const selectedStock = stocks.find(stock => stock.StockName === value);
    setSelectedMaterials(prev => ({
      ...prev,
      [key]: selectedStock
    }));
  };

  const onFinish = async (values) => {
    const currentTime = moment().format("DD-MM-YYYY HH:mm:ss");
    const recipeData = {
      ...values,
      recipeNumber, // This includes the recipe number in the data to be saved
      totalBatchKg,
      packagingWeight: values.packagingWeight, // Ambalaj kilogramını ekleyin
      creationDate: moment(values.creationDate).format("DD-MM-YYYY"),
      lastUpdateDate: currentTime,
      history: arrayUnion({
        updateDate: currentTime,
        updater: values.lastUpdater || user,
      }),
    };

    try {
      if (isUpdate && currentRecipeId) {
        await updateDoc(doc(db, "productRecipe", currentRecipeId), recipeData);
        message.success("Reçete başarıyla güncellendi!");
      } else {
        await addDoc(collection(db, "productRecipe"), recipeData);
        message.success("Reçete başarıyla oluşturuldu!");
      }

      form.resetFields();
      setIsUpdate(false);
      setCurrentRecipeId(null);
      setTotalBatchKg(0);
      fetchRecipes();
    } catch (error) {
      console.log("Error:", error);
      message.error("Bir hata oluştu, lütfen tekrar deneyin.");
    }
  };

  const calculateTotalBatchKg = (rawMaterials) => {
    const total = rawMaterials.reduce((sum, material) => sum + (parseFloat(material?.amount) || 0), 0); // Summing 'amount' instead of 'UnitAmount'
    console.log(total)
    setTotalBatchKg(total);
  };
  

  const handleRawMaterialsChange = () => {
    const rawMaterials = form.getFieldValue('rawMaterials') || [];
    calculateTotalBatchKg(rawMaterials);
  };

  const showHistory = async () => {
    if (currentRecipeId) {
      const recipeDoc = await getDoc(doc(db, "productRecipe", currentRecipeId));
      const recipe = recipeDoc.data();
      setHistoryData(recipe.history);
      setHistoryModalVisible(true);
    } else {
      message.error("Lütfen önce bir reçete seçin.");
    }
  };

  const selectRecipe = async (id) => {
    const recipeDoc = await getDoc(doc(db, "productRecipe", id));
    const recipe = recipeDoc.data();
    form.setFieldsValue({
      creationDate: moment(recipe.creationDate, "DD-MM-YYYY"),
      productName: recipe.productName,
      productCategory: recipe.productCategory,
      subCategory: recipe.subCategory,
      qualityCode: recipe.qualityCode,
      rawMaterials: recipe.rawMaterials,
      productionRoute: recipe.productionRoute,
      creator: recipe.creator,
      warehouse: recipe.warehouse,  // Set the warehouse

      packagingWeight: recipe.packagingWeight, // Add this line to set the packagingWeight
      lastUpdater: recipe.creator,
    });
    setRecipeNumber(recipe.recipeNumber);
    setTotalBatchKg(recipe.totalBatchKg);
    setIsUpdate(true);
    setCurrentRecipeId(id);
  };

  const handleSearch = (value) => {
    const filtered = recipes.filter(recipe => recipe.recipeNumber.toLowerCase().includes(value.toLowerCase()));
    setFilteredRecipes(filtered);
  };

  const handleNewRecipe = async () => {
    form.resetFields();
    setTotalBatchKg(0);
    setIsUpdate(false);
    setCurrentRecipeId(null);

    const now = moment();
    const yearMonth = now.format('YYMM'); // Yılın son iki hanesi ve ay
    const prefix = `RCT/${yearMonth}-`;

    const q = query(collection(db, "productRecipe"), orderBy("recipeNumber", "desc"), limit(1));
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      const lastRecipe = snapshot.docs[0].data();
      const lastNumber = parseInt(lastRecipe.recipeNumber.split('-')[1]);
      const newRecipeNumber = `${prefix}${String(lastNumber + 1).padStart(3, '0')}`;
      setRecipeNumber(newRecipeNumber);
    } else {
      setRecipeNumber(`${prefix}${String(1).padStart(3, '0')}`);
    }

    if (categories.length > 0) {
      setSubCategories(categories[0].children ? categories[0].children : []);
      form.setFieldsValue({
        productCategory: categories[0].categoryCode,
        subCategory: categories[0].children ? categories[0].children[0].title : ""
      });
    }
  };

  const handleCategoryChange = (value) => {
    const selectedCategory = categories.find(category => category.categoryCode === value);

    if (selectedCategory && selectedCategory.children) {
      setSubCategories(selectedCategory.children);
      form.setFieldsValue({
        subCategory: selectedCategory.children.length > 0 ? selectedCategory.children[0].title : ""
      });
    } else {
      setSubCategories([]);
      form.setFieldsValue({
        subCategory: ""
      });
    }
  };

  const handleDelete = async (id) => {
    MySwal.fire({
      title: 'Emin misiniz?',
      text: "Bu işlemi geri alamazsınız!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet, sil!',
      cancelButtonText: 'Hayır, iptal et!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, "productRecipe", id));
          message.success("Reçete başarıyla silindi!");
          fetchRecipes();
        } catch (error) {
          console.log("Error:", error);
          message.error("Bir hata oluştu, lütfen tekrar deneyin.");
        }
      }
    });
  };

  const handleWarehouseSelect = (value) => {
    console.log(value)
    fetchMaterialsByWarehouse(value); // Fetch stocks based on selected warehouse
  };

  return (
    <div className="page-wrapper mt-5">
      <Layout>
        <Sider width={300} className="site-layout-background">
          <Search
            placeholder="Reçete Numarası Ara"
            onSearch={handleSearch}
            style={{ marginBottom: 16 }}
          />
          <Button type="primary" icon={<FileAddOutlined />} onClick={handleNewRecipe} style={{ marginBottom: 16 }}>
            Yeni Oluştur
          </Button>
          <Menu mode="inline" style={{ height: '100%', borderRight: 0 }}>
            <Menu.ItemGroup key="g1" title="Oluşturulan Reçeteler">
              {filteredRecipes.map(recipe => (
                <Menu.Item key={recipe.id} onClick={() => selectRecipe(recipe.id)}>
                  {recipe.recipeNumber}
                  <DeleteOutlined onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(recipe.id);
                  }} style={{ float: "right", margin: 10, color: "red" }} />
                </Menu.Item>
              ))}
            </Menu.ItemGroup>
          </Menu>
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content style={{ padding: 24, margin: 0, minHeight: 280 }}>
            <Container style={{ padding: "20px" }}>
              <Card title={isUpdate ? "Reçete Güncelle" : "Reçete Oluştur"} style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", borderRadius: "10px" }}>
                <Form form={form} onFinish={onFinish} layout="vertical" onFieldsChange={handleRawMaterialsChange}>
                  <Row gutter={16}>
                    <Col md={8}>
                      <Form.Item name="creationDate" label="Reçete Oluşturma Tarihi" rules={[{ required: true, message: "Lütfen tarih seçiniz!" }]}>
                        <DatePicker format={'DD-MM-YYYY'} style={{ width: "100%", margin: "8px 0" }} />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label="Reçete Numarası">
                        <Input value={recipeNumber} disabled style={{ width: "100%", margin: "8px 0" }} />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item name="productName" label="Ürün Adı" rules={[{ required: true, message: "Lütfen ürün adını seçiniz!" }]}>
                        <Select
                          showSearch
                          placeholder="Ürün Adı Seç"
                          style={{ width: "100%", margin: "8px 0" }}
                        >
                          {stocks.map(stock => (
                            <Option key={stock.id} value={stock.StockName}>
                              {stock.StockName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Warehouse Selection */}
                  <Row gutter={16}>
                    <Col md={8}>
                      <Form.Item name="warehouse" label="Depo Seçiniz" rules={[{ required: true, message: "Lütfen depo seçiniz!" }]}>
                        <Select
                          placeholder="Depo Seç"
                          onChange={handleWarehouseSelect}
                          style={{ width: "100%", margin: "8px 0" }}
                        >
                          {warehouses.map(warehouse => (
                            <Option key={warehouse.id} value={warehouse.id}>
                              {warehouse.depoAdi}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col md={8}>
                      <Form.Item name="productCategory" label="Ürün Kategorisi" rules={[{ required: true, message: "Lütfen ürün kategorisini giriniz!" }]}>
                        <Select onChange={handleCategoryChange} style={{ width: "100%", margin: "8px 0" }}>
                          {categories.map(category => (
                            <Option key={category.categoryCode} value={category.categoryCode}>{category.title}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item name="packagingWeight" label="Ambalaj Kilogramı (kg)" rules={[{ required: true, message: "Lütfen ambalaj kilogramını giriniz!" }]}>
                        <Input min={0} style={{ width: "100%", margin: "8px 0" }} />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item name="subCategory" label="Alt Kategori" rules={[{ required: true, message: "Lütfen alt kategoriyi giriniz!" }]}>
                        <Select style={{ width: "100%", margin: "8px 0" }}>
                          {subCategories.map(subCategory => (
                            <Option key={subCategory.title} value={subCategory.title}>{subCategory.title}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item name="qualityCode" label="Kalite Kodu" >
                        <Input style={{ width: "100%", margin: "8px 0" }} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider orientation="left">Hammadde Bilgileri</Divider>
                  <Form.List name="rawMaterials">
  {(fields, { add, remove }) => (
    <>
      {fields.map(({ key, name, fieldKey, ...restField }) => (
        <Row gutter={16} key={key}>
          <Col span={12}>
            <Form.Item
              {...restField}
              name={[name, "materialName"]}
              fieldKey={[fieldKey, "materialName"]}
              label="Hammadde Adı"
              rules={[{ required: true, message: "Lütfen hammadde adını giriniz!" }]}
            >
              <Select
                showSearch
                placeholder="Hammadde Seç"
                onChange={(value) => handleMaterialChange(value, key)}
                style={{ width: "100%", margin: "8px 0" }}
              >
                {materials.map(stock => (
                  <Option key={stock.id} value={stock.StockName}>
                  {console.log(stock)}
                    {stock.StockName} {/* Display the StockName instead of the ID */}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              {...restField}
              name={[name, "type"]}
              fieldKey={[fieldKey, "type"]}
              required
              label="Cinsi"
            >
              <Input style={{ width: "100%", margin: "8px 0" }} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              {...restField}
              name={[name, "amount"]}
              fieldKey={[fieldKey, "amount"]}
              label="Miktar (kg)"
              rules={[{ required: true, message: "Lütfen miktarı giriniz!" }]}
            >
              <InputNumber min={0} style={{ width: "100%", margin: "8px 0" }} />
            </Form.Item>
          </Col>
          <Col span={22}>
            <Form.Item
              {...restField}
              name={[name, "description"]}
              fieldKey={[fieldKey, "description"]}
              label={`Açıklama ${key + 1}`}
            >
              <Input style={{ width: "100%", margin: "8px 0" }} />
            </Form.Item>
          </Col>
          <Col span={1} style={{ display: 'flex', alignItems: 'center' }}>
            <MinusCircleOutlined onClick={() => remove(name)} style={{ marginLeft: "10px", fontSize: "24px", color: "red", cursor: "pointer" }} />
          </Col>
        </Row>
      ))}
      <Form.Item>
        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
          Hammadde Ekle
        </Button>
      </Form.Item>
    </>
  )}
</Form.List>


                  <Divider />
                  <Col md={24}>
                    <Form.Item name="productionRoute" label="Üretim Yolu" rules={[{ required: true, message: "Lütfen üretim yolunu giriniz!" }]}>
                      <Input.TextArea style={{ width: "100%", margin: "8px 0", height: 125 }} />
                    </Form.Item>
                  </Col>
                  <Row gutter={16}>
                    <Col md={8}>
                      <Form.Item label="Toplam Parti (kg)">
                        <Input value={totalBatchKg} disabled style={{ width: "100%", margin: "8px 0" }} />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item initialValue={user.toString()} name="creator" label="Oluşturan" rules={[{ required: true, message: "Lütfen oluşturanı giriniz!" }]}>
                        <Input disabled style={{ width: "100%", margin: "8px 0" }} />
                      </Form.Item>
                    </Col>
                    {isUpdate ? <Col md={8}>
                      <Form.Item initialValue={user.toString()} name="lastUpdater" label="Son Güncelleyen" rules={[{ required: true, message: "Lütfen son güncelleyeni giriniz!" }]}>
                        <Input disabled style={{ width: "100%", margin: "8px 0" }} />
                      </Form.Item>
                    </Col> : null}
                  </Row>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ marginTop: "20px" }}>
                      {isUpdate ? "Güncelle" : "Oluştur"}
                    </Button>
                  </Form.Item>
                </Form>
                <Button type="dashed" onClick={showHistory} icon={<HistoryOutlined />} style={{ marginTop: "20px" }}>
                  Geçmişi Göster
                </Button>
                <Modal
                  title="Güncelleme Geçmişi"
                  visible={historyModalVisible}
                  onCancel={() => setHistoryModalVisible(false)}
                  footer={<Button onClick={() => setHistoryModalVisible(false)}>Kapat</Button>}
                >
                  <List
                    itemLayout="horizontal"
                    dataSource={historyData}
                    renderItem={item => (
                      <List.Item>
                        <List.Item.Meta
                          title={`Güncelleyen: ${item.updater}`}
                          description={`Tarih: ${item.updateDate}`}
                        />
                      </List.Item>
                    )}
                  />
                </Modal>
              </Card>
            </Container>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default ReceteOlustur;
