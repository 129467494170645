import React, { useState, useEffect, useRef } from 'react';
import { Card, Col, Row, Typography, Modal, Button, Form, Input, Select, Popconfirm, DatePicker, Table } from 'antd';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection, query, orderBy, doc, updateDoc, deleteDoc, where, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../../environment';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { useReactToPrint } from 'react-to-print';
import PropTypes from 'prop-types';
import logo from '../../assets/yclkimya.png';

const { Title, Text } = Typography;
const { Option } = Select;
const role = localStorage.getItem('role');

const PrintableForm = React.forwardRef((props, ref) => {
  const { formValues } = props;

  

 

 

  const headerStyle = {
    textAlign: 'center',
    marginBottom: '2px',
    fontSize: '10px',
  };

  const sectionStyle = {
    marginBottom: '8px',
    padding: '6px',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
    fontSize: '8px',
  };

  const rowStyle = {
    gutter: [3, 3],
    fontSize: '8px',
  };

  // Toplam şarj (totalCells) değerine göre tabloyu oluşturma

  return (
    <div ref={ref} className="printable-form" style={{ fontSize: '8px', padding: '5px', lineHeight: '1.2', fontFamily: 'Arial, sans-serif', backgroundColor: '#f4f4f4' }}>
      <header style={headerStyle}>
        <img src={logo} alt="Logo" style={{ width: '80px', marginBottom: '-10px', marginTop: '-10px' }} />
        <h1 style={{ color: '#333', fontSize: '12px', margin: 0 }}>Üretim Veri Formu</h1>
      </header>
      
      <section className="form-section" style={sectionStyle}>
        <h2 style={{ color: '#0056b3', fontSize: '10px', marginBottom: '4px' }}>Üretim Detayları</h2>
        <Row gutter={[1, 1]} style={rowStyle}>
          <Col span={8}>
            <p style={{ color: 'red' }}>
              <strong>Reçete Numarası:</strong> {formValues.recipe_number}
            </p>
          </Col>
          <Col span={8}>
            <p><strong>Başlama Tarihi:</strong> {formValues.start_date}</p>
          </Col>
          <Col span={8}>
            <p><strong>Bitiş Tarihi:</strong> {formValues.end_date}</p>
          </Col>
          <Col span={8}>
            <p style={{ color: 'red' }}><strong>Sipariş Numarası:</strong> {formValues.card_number}</p>
          </Col>
        </Row>

        
      </section>
      
      <section className="form-section" style={sectionStyle}>
        <h2 style={{ color: '#0056b3', fontSize: '10px', marginBottom: '4px' }}>Reçete Seçimi</h2>
        <Row gutter={[2, 2]} style={rowStyle}>
          <Col span={8}><p><strong>Şarj Sayısı:</strong> {formValues.totalCells}</p></Col>
          <Col span={8}><p><strong>Ürün Adı:</strong> {formValues.product_name}</p></Col>
          <Col span={8}><p><strong>Ambalaj Kilogramı:</strong> {formValues.packaging_weight}</p></Col>
        </Row>
        <Row gutter={[2, 2]} style={rowStyle}>
          <Col span={8}><p><strong>1 Şarj Toplam Kg:</strong> {formValues.batch_total_kg}</p></Col>
          <Col span={8}><p><strong>Toplam Üretim Miktarı:</strong> {formValues.total_production_amount}</p></Col>
          <Col span={8}><p><strong>Toplam Üretim Miktarı Adedi:</strong> {formValues.total_production_amount_pcs}</p></Col>
        </Row>
      </section>
      
      <section className="form-section" style={sectionStyle}>
  <h2 style={{ color: '#0056b3', fontSize: '10px', marginBottom: '4px' }}>Üretimde Kullanılan Hammaddeler</h2>
  <Table
    dataSource={formValues.rawMaterials}
    columns={[
      { title: 'Adı', dataIndex: 'materialName', key: 'materialName' },
      { title: 'Cinsi', dataIndex: 'type', key: 'type' },
      { title: 'Miktar (kg)', dataIndex: 'amount', key: 'amount' },
      {
        title: 'Toplam Kullanılacak Miktar (kg)',
        key: 'totalAmount',
        render: (text, record) => {
          // Şarj sayısı ve miktarı çarpıyoruz
          const totalCells = formValues.totalCells || 1;
          const amount = record.amount || 0;
          return <span>{totalCells * amount} kg</span>;
        },
      },
      { title: 'Açıklama', dataIndex: 'description', key: 'description' },
    ]}
    pagination={false}
    rowKey={(record) => record.materialName + record.type}
    bordered
    size="small"
  />
</section>

      
      <section className="form-section" style={sectionStyle}>
        <h2 style={{ color: '#0056b3', fontSize: '10px', marginBottom: '4px' }}>Üretim Kartı Detayları</h2>
        <Row gutter={[2, 2]} style={rowStyle}>
          <Col span={8}><p><strong>Üretim Kartını Oluşturan:</strong> {formValues.creator_name}</p></Col>
          <Col span={8}><p><strong>Üretim Yapan Personel:</strong> {formValues.production_staff_name}</p></Col>
        </Row>
      </section>
      
      <section className="form-section" style={sectionStyle}>
        <Row gutter={[2, 2]} style={rowStyle}>
          <Col span={12}><p><strong>Notlar:</strong> {formValues.notes}</p></Col>
        </Row>
      </section>
    </div>
  );
});



PrintableForm.displayName = "PrintableForm";

PrintableForm.propTypes = {
  formValues: PropTypes.shape({
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    recipe_number: PropTypes.string,
    totalCells:PropTypes.number,
    card_number: PropTypes.string,
    product_name: PropTypes.string,
    packaging_weight: PropTypes.number,
    batch_total_kg: PropTypes.number,
    total_production_amount: PropTypes.number,
    total_production_amount_pcs: PropTypes.number,
    rawMaterials: PropTypes.arrayOf(
      PropTypes.shape({
        materialName: PropTypes.string,
        type: PropTypes.string,
        amount: PropTypes.number,
        description: PropTypes.string,
      })
    ),
    creator_name: PropTypes.string,
    production_staff_name: PropTypes.string,
    additional_info: PropTypes.string,
    notes: PropTypes.string,
    note_writer: PropTypes.string,
  }).isRequired,
  selectedBatch: PropTypes.object.isRequired,
  batchRows: PropTypes.number.isRequired,
  batchColumns: PropTypes.number.isRequired,
};

export default function TumUretimKartlari() {
  const productionDataRef = collection(db, 'productionData');
  const q = query(productionDataRef, orderBy('card_number'));
  const [productionData] = useCollectionData(q, { idField: 'id' });

  const materialsRef = collection(db, 'materials');
  const materialsQuery = query(materialsRef, orderBy('MaterialName'));
  const [materialData] = useCollectionData(materialsQuery, { idField: 'id' });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [materialOptions, setMaterialOptions] = useState([]);
  const printableRef = useRef();

  useEffect(() => {
    if (materialData) {
      setMaterialOptions(materialData.map(material => material.MaterialName));
    }
  }, [materialData]);

  const showModal = (data) => {
    setSelectedCard(data);
    setIsModalVisible(true);
  };

  const handleFormChange = (changedValues, allValues) => {
    const updatedCard = { ...selectedCard };
  
    // rawMaterials'i güvenli bir şekilde alın ve kontrol edin
    const rawMaterials = Array.isArray(allValues.rawMaterials) ? allValues.rawMaterials : selectedCard.rawMaterials || [];
  
    // Recalculate batchTotalKg based on rawMaterials
    const batchTotalKg = rawMaterials.reduce((total, material) => {
      const amount = parseFloat(material?.amount) || 0;
      return total + amount;
    }, 0);
  
    // totalCells değeri formdan alınırsa kullan, yoksa eski değer
    const batchQuantity = allValues.totalCells !== undefined ? allValues.totalCells : selectedCard.totalCells || 1;
  
    const packagingWeight = allValues.packaging_weight || selectedCard.packaging_weight || 1;
  
    // Calculate total production amount
    const totalProductionAmount = batchTotalKg * batchQuantity;
    const totalProductionAmountPcs = totalProductionAmount / packagingWeight;
  
    // Update the selectedCard with new values
    updatedCard.batch_total_kg = batchTotalKg;
    updatedCard.total_production_amount = totalProductionAmount;
    updatedCard.total_production_amount_pcs = totalProductionAmountPcs;
    updatedCard.totalCells = batchQuantity; // Burada güncellenen totalCells değeri aktarılıyor
  
    setSelectedCard(updatedCard);
  };
  
  
  

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedCard(null);
    setIsEditing(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async (values) => {
    if (selectedCard && selectedCard.card_number) {
      try {
        const q = query(productionDataRef, where("card_number", "==", selectedCard.card_number));
        const querySnapshot = await getDocs(q);
  
        querySnapshot.forEach(async (docSnapshot) => {
          const cardDocRef = doc(db, 'productionData', docSnapshot.id);
  
          // rawMaterials'i sanitize et
          const sanitizeRawMaterials = (materials) => {
            return materials.map((material) => ({
              ...material,
              description: material.description ?? null, // Description boş olabilir
            }));
          };
  
          // Değerleri sanitize et ve undefined olanları null'a çevir
          const sanitizeValues = (obj) => {
            return Object.fromEntries(
              Object.entries(obj).map(([key, value]) => {
                if (key === 'rawMaterials' && Array.isArray(value)) {
                  return [key, sanitizeRawMaterials(value)];
                }
                return [key, value === undefined ? null : value];
              })
            );
          };
  
          // Formdan gelen güncellenmiş values ile birlikte selectedCard'ı birleştir
          const updatedValues = sanitizeValues({
            ...selectedCard, // selectedCard verilerini al
            ...values, // formdan gelen yeni verileri ekle
            start_date: values.start_date ? dayjs(values.start_date).format('DD-MM-YYYY') : selectedCard.start_date,
            end_date: values.end_date ? dayjs(values.end_date).format('DD-MM-YYYY') : selectedCard.end_date,
            rawMaterials: values.rawMaterials || selectedCard.rawMaterials, // rawMaterials formdan geliyorsa kullan, yoksa eski değer
            total_production_amount: selectedCard.total_production_amount,
            total_production_amount_pcs: selectedCard.total_production_amount_pcs,
            batch_total_kg: selectedCard.batch_total_kg,
            totalCells: selectedCard.totalCells,
            selectedBatch: selectedCard.selectedBatch,
          });
  
          // Firestore dokümanını güncelle
          await updateDoc(cardDocRef, updatedValues);
        });
  
        setIsEditing(false);
        setIsModalVisible(false);
        Swal.fire({
          title: 'Başarılı!',
          text: 'Üretim kartı başarıyla güncellendi.',
          icon: 'success',
          confirmButtonText: 'Tamam'
        });
      } catch (error) {
        console.error('Güncelleme hatası:', error);
        Swal.fire({
          title: 'Hata!',
          text: 'Güncelleme sırasında bir hata oluştu.',
          icon: 'error',
          confirmButtonText: 'Tamam'
        });
      }
    }
  };
  

  const handleApproval = async (data) => {
    try {
  
      // Stoktan düşürme ve stoğa ekleme işlemleri kaldırıldı
      // Artık sadece üretim kartı onaylanacak ve ilgili dokümanlar taşınacak
  
      // Üretim kartını onaylananlar koleksiyonuna taşı
      await addDoc(collection(db, 'onaylananUretimKartlari'), {
        ...data,
        approval_date: dayjs().format('DD-MM-YYYY HH:mm:ss'),
      });
  
      // Orijinal dokümanı sil
      const q = query(collection(db, 'productionData'), where('card_number', '==', data.card_number));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (docSnapshot) => {
        const cardDocRef = doc(db, 'productionData', docSnapshot.id);
        await deleteDoc(cardDocRef);
      });
  
      Swal.fire({
        title: 'Onaylandı!',
        text: 'Üretim kartı başarıyla onaylandı.',
        icon: 'success',
        confirmButtonText: 'Tamam',
      });
  
      setIsModalVisible(false);
    } catch (error) {
      console.error('Onaylama hatası:', error);
      Swal.fire({
        title: 'Hata!',
        text: `Onaylama sırasında bir hata oluştu: ${error.message}`,
        icon: 'error',
        confirmButtonText: 'Tamam',
      });
    }
  };
  
  
  
  
  

  const handleDelete = async () => {
    if (selectedCard && selectedCard.card_number) {
      try {
        const q = query(productionDataRef, where("card_number", "==", selectedCard.card_number));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (docSnapshot) => {
          const cardDocRef = doc(db, 'productionData', docSnapshot.id);
          await deleteDoc(cardDocRef);
        });

        setIsModalVisible(false);
        Swal.fire({
          title: 'Silindi!',
          text: 'Üretim kartı başarıyla silindi.',
          icon: 'success',
          confirmButtonText: 'Tamam'
        });
      } catch (error) {
        console.error('Silme hatası:', error);
        Swal.fire({
          title: 'Hata!',
          text: 'Silme sırasında bir hata oluştu.',
          icon: 'error',
          confirmButtonText: 'Tamam'
        });
      }
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  return (
    <div className='page-wrapper'>
      <Title level={2}>Onay Bekleyen Üretim Kartları</Title>
      <Row gutter={[16, 16]}>
        {productionData && productionData.map((data) => (
          <Col xs={24} sm={12} md={8} lg={6} key={data.id}>
            <Card
              title={data.card_number}
              bordered={false}
              style={{
                backgroundColor: data.card_number.startsWith('RUK')
                  ? '#E0F7FA'
                  : data.card_number.startsWith('SUK')
                    ? '#EDE7F6'
                    : '#fff'
              }}
              hoverable
              onClick={() => showModal(data)}
            >
              <p><Text strong>Ürün Adı:</Text> {data.product_name}</p>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        title="Üretim Kartı Detayları"
        visible={isModalVisible}
        onCancel={handleCancel}
        width={1250}
        footer={[
          <Button key="close" onClick={handleCancel}>Kapat</Button>,
          isEditing ? (
            <Button key="save" type="primary" form="editForm" htmlType="submit">Kaydet</Button>
          ) : (
            <>
              <Button key="edit" type="primary" onClick={handleEdit}>Düzenle</Button>

              <Popconfirm
                title="Bu üretim kartını silmek istediğinize emin misiniz?"
                onConfirm={handleDelete}
                okText="Evet"
                cancelText="Hayır"
              >
                <Button key="delete" danger>Sil</Button>
              </Popconfirm>
              <Button key="print" type="default" onClick={handlePrint}>Yazdır</Button>
              {role === "Sistem Admini" ? <Button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => handleApproval(selectedCard)}>Onayla</Button> : null}
            </>
          )
        ]}
      >
      {console.log(selectedCard)}
      {selectedCard && !isEditing && (
  <div>
    <p><Text strong>Kart Numarası:</Text> {selectedCard.card_number}</p>
    <p><Text strong>Ürün Adı:</Text> {selectedCard.product_name}</p>
    <p><Text strong>Paketleme Ağırlığı:</Text> {selectedCard.packaging_weight} kg</p>
    <p><Text strong>Üretim Personeli:</Text> {selectedCard.production_staff_name}</p>
    <p><Text strong>Başlangıç Tarihi:</Text> {selectedCard.start_date}</p>
    <p><Text strong>Bitiş Tarihi:</Text> {selectedCard.end_date}</p>
    <p><Text strong>Ek Bilgi:</Text> {selectedCard.additional_info}</p>
    <p><Text strong>Hammadde Listesi:</Text></p>
    <Table
      dataSource={selectedCard.rawMaterials}
      columns={[
        { title: 'Adı', dataIndex: 'materialName', key: 'materialName','align':'right' },
        { title: 'Cinsi', dataIndex: 'type', key: 'type','align':'right' },
        { title: 'Miktar (kg)', dataIndex: 'amount', key: 'amount','align':'right' },
        {
          title: 'Toplam Kullanılacak Miktar (kg)',
          key: 'totalAmount',
          'align':'right',
          render: (text, record) => {
            // Şarj sayısı ve miktarı çarpıyoruz
            const totalCells = selectedCard.totalCells || 1;
            const amount = record.amount || 0;
            return <span>{(totalCells * amount).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} kg</span>;
          },
        },
        { title: 'Açıklama', dataIndex: 'description', key: 'description','align':'right' },
      ]}
      pagination={false}
      rowKey={(record) => record.materialName + record.type}
      bordered

      size="small"
    />
  </div>
)}


        {selectedCard && isEditing && (
          <Form
  id="editForm"
  initialValues={{
    ...selectedCard,
    totalCells: selectedCard.totalCells || 1,
    start_date: selectedCard.start_date ? dayjs(selectedCard.start_date, 'DD-MM-YYYY') : null,
    end_date: selectedCard.end_date ? dayjs(selectedCard.end_date, 'DD-MM-YYYY') : null,
    rawMaterials: selectedCard.rawMaterials || [],
  }}
  onFinish={handleSave}
  onValuesChange={handleFormChange}  // onFieldsChange yerine onValuesChange kullanılıyor
  layout="vertical"
  style={{ maxHeight: '70vh', overflowY: 'auto' }}
>

    <Form.Item name="card_number" label="Kart Numarası">
      <Input />
    </Form.Item>
    <Form.Item name="product_name" label="Ürün Adı">
      <Input />
    </Form.Item>
    <Form.Item name="packaging_weight" label="Paketleme Ağırlığı">
      <Input type="number" addonAfter="kg" />
    </Form.Item>
    <Form.Item name="totalCells" label="Şarj Sayısı">
      <Input type="number" />
    </Form.Item>
    
    <Form.Item name="production_staff_name" label="Üretim Personeli">
      <Input />
    </Form.Item>
    <Form.Item name="start_date" label="Başlangıç Tarihi">
      <DatePicker format="DD-MM-YYYY" />
    </Form.Item>
    <Form.Item name="end_date" label="Bitiş Tarihi">
      <DatePicker format="DD-MM-YYYY" />
    </Form.Item>
    <Form.Item name="production_status" label="Üretim Durumu">
      <Select>
        <Option value="Üretiliyor">Üretiliyor</Option>
        <Option value="Üretildi">Üretildi</Option>
        <Option value="Hazırlık Aşamasında">Hazırlık Aşamasında</Option>
      </Select>
    </Form.Item>

    <Form.List name="rawMaterials">
  {(fields, { add, remove }) => (
    <>
      <Table
        dataSource={selectedCard.rawMaterials || []}
        columns={[
          {
            title: 'Malzeme Adı',
            dataIndex: 'materialName',
            key: 'materialName',
            render: (text, record, index) => (
              <Form.Item
                name={[index, 'materialName']}
                initialValue={record.materialName}
                rules={[{ required: true, message: 'Malzeme Adı gerekli!' }]}
              >
                <Select placeholder="Malzeme Seçin">
                  {materialOptions.map((material, i) => (
                    <Option key={i} value={material}>
                      {material}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ),
          },
          {
            title: 'Tip',
            dataIndex: 'type',
            key: 'type',
            render: (text, record, index) => (
              <Form.Item
                name={[index, 'type']}
                initialValue={record.type}
                rules={[{ required: true, message: 'Tip gerekli!' }]}
              >
                <Input placeholder="Tip Girin" />
              </Form.Item>
            ),
          },
          {
            title: 'Miktar (kg)',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record, index) => (
              <Form.Item
                name={[index, 'amount']}
                initialValue={record.amount}
                rules={[{ required: true, message: 'Miktar gerekli!' }]}
              >
                <Input type="number" addonAfter="kg" placeholder="Miktar Girin" />
              </Form.Item>
            ),
          },
          {
            title: 'Toplam Kullanılacak Miktar (kg)',
            key: 'totalAmount',
            render: (text, record, ) => {
              // Şarj sayısı ile miktarı çarpıyoruz
              const totalCells = selectedCard.totalCells || 1;
              const amount = record.amount || 0;
              return <span>{totalCells * amount} kg</span>;
            },
          },
          {
            title: 'Açıklama',
            dataIndex: 'description',
            key: 'description',
            render: (text, record, index) => (
              <Form.Item
                name={[index, 'description']}
                initialValue={record.description}
              >
                <Input.TextArea placeholder="Açıklama Girin" />
              </Form.Item>
            ),
          },
          {
            title: 'İşlem',
            key: 'action',
            render: (text, record, index) => (
              <Button onClick={() => remove(index)} danger>Sil</Button>
            ),
          },
        ]}
        pagination={false}
        bordered
        size="small"
      />
      <Button
        type="dashed"
        onClick={() => add()}
        block
        style={{ marginTop: '10px', fontWeight: 'bold' }}
      >
        + Hammadde Ekle
      </Button>
    </>
  )}
</Form.List>


    <Form.Item name="additional_info" label="Ek Bilgi" style={{ marginTop: '20px' }}>
      <Input.TextArea />
    </Form.Item>
  </Form>
)}

      </Modal>

      <div style={{ display: 'none' }}>
        {selectedCard && (
          <PrintableForm
            ref={printableRef}
            formValues={selectedCard}
            selectedBatch={selectedCard.selectedBatch || {}}
            batchRows={selectedCard.batchRows || 0}
            batchColumns={selectedCard.batchColumns || 0}
          />
        )}
      </div>
    </div>
  );
}
