import React from 'react';

import * as Icon from 'react-feather';

export const SidebarData = [
  {

    label: "Sipariş Yönetimi",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Sipariş Yönetimi",
    submenuItems: [
      {
        label: "Satış",
        icon: <Icon.Grid />,
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          // { label: "Satışlar", link: "/sales-list", icon: <Icon.ShoppingCart />, showSubRoute: false, submenu: false },
          { label: "Proforma Faturalar", link: "/proforma-faturalar", icon: <Icon.Save />, showSubRoute: false, submenu: false },
          { label: "Normal Sipariş Formu", link: "/quotation-list", icon: <Icon.Save />, showSubRoute: false, submenu: false },
          { label: "Ticari Fatura Formu", link: "/commercial-invoice", icon: <Icon.Briefcase />, showSubRoute: false, submenu: false },
          { label: "Paket Listesi Formu", link: "/paketlistesi", icon: <Icon.Package />, showSubRoute: false, submenu: false },

          // { label: "Proforma Fatura Formu", link: "/proforma-fatura-form", icon: <Icon.Save />, showSubRoute: false, submenu: false },
          { label: "Onay Bekleyen Siparişler", link: "/onay-bekleyen-siparisler", icon: <Icon.Save />, showSubRoute: false, submenu: false },
          { label: "Onaylanan Siparişler", link: "/onaylanan-siparisler", icon: <Icon.Save />, showSubRoute: false, submenu: false },
          { label: "Teslim Edilen Siparişler", link: "/teslim-edilen-siparisler", icon: <Icon.Save />, showSubRoute: false, submenu: false },
          { label: "Numune Takibi", link: "/numune-takibi", icon: <Icon.AtSign />, showSubRoute: false, submenu: false },

      // { label: "Faturalar", link: "/invoice-report", icon: <Icon.FileText />, showSubRoute: false, submenu: false },
      // { label: "Satış İadeleri", link: "/sales-returns", icon: <Icon.Copy />, showSubRoute: false, submenu: false },
      // { label: "POS", link: "/pos", icon: <Icon.HardDrive />, showSubRoute: false, submenu: false }

        ]
      },
      {
        label: "Satın Alma",
        icon: <Icon.Grid />,
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          { label: "Normal Satın Alma Formu", link: "/purchase-list", icon: <Icon.ShoppingBag />, showSubRoute: false, submenu: false },
          { label: "Onay Bekleyen Satın Alımlar", link: "/satin-alma-onay-bekleyen-siparisler", icon: <Icon.Save />, showSubRoute: false, submenu: false },
          { label: "Onaylanan Satın Alımlar", link: "/satin-alma-onaylanan-siparisler", icon: <Icon.Save />, showSubRoute: false, submenu: false },
          { label: "Teslim Edilen Satın Alımlar", link: "/satin-alma-teslim-edilen-siparisler", icon: <Icon.Save />, showSubRoute: false, submenu: false },

        ]
      },


    ]
  },
  // {

  //   label: "Üretim Yönetimi",
  //   submenuOpen: true,
  //   showSubRoute: false,
  //   submenuHdr: "Üretim Yönetimi",
  //   submenuItems: [
  //     {
  //       label: "Gösterge Paneli",
  //       icon: <Icon.Grid />,
  //       submenu: true,
  //       showSubRoute: false,
  //       submenuItems: [
  //         { label: "Yönetici Gösterge Paneli", link: "/" },
  //         { label: "Satış Gösterge Paneli", link: "/sales-dashboard" }
  //       ]
  //     },

      // {
      //   label: "Uygulama",
      //   icon: <Icon.Smartphone />,
      //   submenu: true,
      //   showSubRoute: false,
      //   submenuItems: [
      //     { label: "Sohbet", link: "/chat", showSubRoute: false },
      //     {
      //       label: "Arama",
      //       submenu: true,
      //       submenuItems: [
      //         { label: "Video Arama", link: "/video-call" },
      //         { label: "Sesli Arama", link: "/audio-call" },
      //         { label: "Arama Geçmişi", link: "/call-history" }
      //       ]
      //     },
      //     { label: "Takvim", link: "/calendar", showSubRoute: false },
      //     { label: "E-posta", link: "/email", showSubRoute: false },
      //     { label: "Yapılacaklar", link: "/todo", showSubRoute: false },
      //     { label: "Notlar", link: "/notes", showSubRoute: false },
      //     { label: "Dosya Yöneticisi", link: "/file-manager", showSubRoute: false }
      //   ]
      // },
      {

        label: "Hızlı Ekle",
        submenuOpen: true,
        showSubRoute: false,
        submenuHdr: "Hızlı Ekle",
        submenuItems: [
          {
            label: "Cariler",
            icon: <Icon.User />,
            submenu: true,
            showSubRoute: false,
            submenuItems: [
              // { label: "Satışlar", link: "/sales-list", icon: <Icon.ShoppingCart />, showSubRoute: false, submenu: false },
              { label: "Tüm  ", link: "/cari-tum-islemler", icon: <Icon.Save />, showSubRoute: false, submenu: false },
             
    
          // { label: "Faturalar", link: "/invoice-report", icon: <Icon.FileText />, showSubRoute: false, submenu: false },
          // { label: "Satış İadeleri", link: "/sales-returns", icon: <Icon.Copy />, showSubRoute: false, submenu: false },
          // { label: "POS", link: "/pos", icon: <Icon.HardDrive />, showSubRoute: false, submenu: false }
    
            ]
          },
         
    
    
        ]
      },
      {
        label: "Uygulama",
        icon: <Icon.Smartphone />,
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          // { label: "Sohbet", link: "/chat", showSubRoute: false },
          {
            label: "Arama",
            submenu: true,
            submenuItems: [
              { label: "Video Arama", link: "/video-call" },
              // { label: "Sesli Arama", link: "/audio-call" },
              // { label: "Arama Geçmişi", link: "/call-history" }
            ]
          },
          // { label: "Takvim", link: "/calendar", showSubRoute: false },
          // { label: "E-posta", link: "/email", showSubRoute: false },
          // { label: "Yapılacaklar", link: "/todo", showSubRoute: false },
          // { label: "Notlar", link: "/notes", showSubRoute: false },
          // { label: "Dosya Yöneticisi", link: "/file-manager", showSubRoute: false }
        ]
      },
      {
        label: "Tablet",
        icon: <Icon.Smartphone />,
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          // { label: "Sohbet", link: "/chat", showSubRoute: false },
          {
            label: "Tablet İşlemleri",
            submenu: true,
            submenuItems: [
              { label: "Üretim Kartı", link: "/tableturetimkarti" },
              // { label: "Sesli Arama", link: "/audio-call" },
              // { label: "Arama Geçmişi", link: "/call-history" }
            ]
          },
          // { label: "Takvim", link: "/calendar", showSubRoute: false },
          // { label: "E-posta", link: "/email", showSubRoute: false },
          // { label: "Yapılacaklar", link: "/todo", showSubRoute: false },
          // { label: "Notlar", link: "/notes", showSubRoute: false },
          // { label: "Dosya Yöneticisi", link: "/file-manager", showSubRoute: false }
        ]
      },
  //   ]
  // },
  {
    label: "Üretim Yönetimi",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Üretim Yönetimi",
    submenuItems: [
      {
        label: "Reçeteler",
        icon: <Icon.Grid />,
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          // { label: "Kayıtlı Reçeteler", link: "/kayitli-receteler" },
          // { label: "Son Reçeteler", link: "/son-receteler" },
          { label: "Reçete Oluştur", link: "/recete-olustur" },

        ]
      },

      {
        label: "Üretim",
        icon: <Icon.Smartphone />,
        submenu: true,
        showSubRoute: false,
        submenuItems: [
          { label: "Siparişten Üretim Kartı Oluştur", link: "/siparisten-uretim-karti-olustur", showSubRoute: false },
          { label: "Reçeteden Üretim Kartı Oluştur", link: "/receteden-uretim-karti-olustur", showSubRoute: false },
          { label: "(Onay Bekleyen) Tüm Üretim Kartları", link: "/tumuretimkartlari", showSubRoute: false },
          { label: "(Onaylanan) Tüm Üretim Kartları", link: "/onaylananuretimkartlari", showSubRoute: false },


          
        ]
      }
    ]
  },
  {
    label: "Envanter",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Envanter",
    submenuItems: [
      { label: "Hammadde Stok Listesi", link: "/hammadde-stok-listesi", icon: <Icon.Box />, showSubRoute: false, submenu: false },
      { label: "Mamüller Stok Listesi", link: "/mamuller-stok-listesi", icon: <Icon.PlusSquare />, showSubRoute: false, submenu: false },
      { label: "Yarı Mamüller Stok Listesi", link: "/yari-mamuller-stok-listesi", icon: <Icon.Codesandbox />, showSubRoute: false, submenu: false },
      { label: "Ambalaj Stok Listesi", link: "/ambalaj-stok-listesi", icon: <Icon.Codesandbox />, showSubRoute: false, submenu: false },
      { label: "Diğer Sarf M. Stok Listesi", link: "/sarf-malzeme-stok-listesi", icon: <Icon.Codesandbox />, showSubRoute: false, submenu: false },
      { label: "Kategorize Stok Listesi", link: "/kategorize-stok-listesi", icon: <Icon.Codesandbox />, showSubRoute: false, submenu: false },
      { label: "Depolara Göre Stok Listesi", link: "/depo-stok-listesi", icon: <Icon.Codesandbox />, showSubRoute: false, submenu: false },
      { label: "Depo Transferleri", link: "/depo-transferleri", icon: <Icon.Codesandbox />, showSubRoute: false, submenu: false },

      { label: "Kritik Seviye Stoklar", link: "/kritik-stok-listesi", icon: <Icon.TrendingDown />, showSubRoute: false, submenu: false },
      { label: "Liste Dışı Bırakılan Stoklar", link: "/liste-disi-stok-listesi", icon: <Icon.Codepen />, showSubRoute: false, submenu: false },
      // { label: "Alt Kategori", link: "/sub-categories", icon: <Icon.Speaker />, showSubRoute: false, submenu: false },
      // { label: "Markalar", link: "/brand-list", icon: <Icon.Tag />, showSubRoute: false, submenu: false },
      // { label: "Birimler", link: "/units", icon: <Icon.Speaker />, showSubRoute: false, submenu: false },
      // { label: "Varyant Özellikleri", link: "/variant-attributes", icon: <Icon.Layers />, showSubRoute: false, submenu: false },
      // { label: "Garantiler", link: "/warranty", icon: <Icon.Bookmark />, showSubRoute: false, submenu: false },
      // { label: "Barkod Yazdır", link: "/barcode", icon: <Icon.AlignJustify />, showSubRoute: false, submenu: false },
      // { label: "QR Kodu Yazdır", link: "/qrcode", icon: <Icon.Maximize />, showSubRoute: false, submenu: false }
    ]
  },
  // {
  //   label: "Stok",
  //   submenuOpen: true,
  //   submenuHdr: "Stok",
  //   submenu: true,
  //   showSubRoute: false,
  //   submenuItems: [
  //     { label: "Stok Yönetimi", link: "/manage-stocks", icon: <Icon.Package />, showSubRoute: false, submenu: false },
  //     { label: "Stok Ayarlama", link: "/stock-adjustment", icon: <Icon.Clipboard />, showSubRoute: false, submenu: false },
  //     { label: "Stok Transferi", link: "/stock-transfer", icon: <Icon.Truck />, showSubRoute: false, submenu: false }
  //   ]
  // },
  {
    label: "Stok",
    submenuOpen: true,
    submenuHdr: "Stok",
    submenu: true,
    showSubRoute: false,
    submenuItems: [
      { label: "Stok Kartı Oluştur", link: "/stok-olustur-duzenle", icon: <Icon.Package />, showSubRoute: false, submenu: false },
      { label: "Stok Düzenle/Güncelle", link: "/stok-guncelle", icon: <Icon.Edit />, showSubRoute: false, submenu: false },
      { label: "Stok Transferi", link: "/stock-transfer", icon: <Icon.Truck />, showSubRoute: false, submenu: false },
      { label: "Stok Kategori Ayarları", link: "/stok-kategori-yonetimi", icon: <Icon.Clipboard />, showSubRoute: false, submenu: false },
      { label: "Giriş İrsaliyesi Oluştur", link: "/giris-irsaliyesi", icon: <Icon.Anchor />, showSubRoute: false, submenu: false },

    ] 
  },
  {
    label: "İnsan Kaynakları",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "İnsan Kaynakları",
    submenuItems: [
      { label: "Vardiyalar", link: "/shift", icon: <Icon.Shuffle />, showSubRoute: false },

      { label: "Personel Listesi", link: "/employees-grid", icon: <Icon.Users />, showSubRoute: false },
      { label: "Departmanlar", link: "/department-grid", icon: <Icon.User />, showSubRoute: false },
      { label: "Görevler", link: "/designation", icon: <Icon.UserCheck />, showSubRoute: false },
      // {
      //   label: "Katılım",
      //   link: "#",
      //   icon: <Icon.Clock />,
      //   showSubRoute: false,
      //   submenu: true,
      //   submenuItems: [
      //     { label: "Çalışan", link: "/attendance-employee" },
      //     { label: "Yönetici", link: "/attendance-admin" }
      //   ]
      // },
      {
        label: "İzinler",
        link: "#",
        icon: <Icon.Calendar />,
        showSubRoute: false,
        submenu: true,
        submenuItems: [
          
          { label: "İzin Talepleri", link: "/leaves-employee" },
          { label: "İzin Türleri", link: "/leave-types" },
          { label: "Resmi Tatiller", link: "/holidays", icon: <Icon.CreditCard />, showSubRoute: false },

        ]
      },
      // {
      //   label: "Bordro",
      //   link: "#",
      //   icon: <Icon.DollarSign />,
      //   showSubRoute: false,
      //   submenu: true,
      //   submenuItems: [
      //     // { label: "Çalışan Maaşı", link: "payroll-list" },
      //     { label: "Maaş Bordrosu", link: "/payslip" }
      //   ]
      // }
    ]
  },
  // {
  //   label: "Satışlar",
  //   submenuOpen: true,
  //   submenuHdr: "Satışlar",
  //   submenu: false,
  //   showSubRoute: false,
  //   submenuItems: [
  //     { label: "Satışlar", link: "/sales-list", icon: <Icon.ShoppingCart />, showSubRoute: false, submenu: false },
  //     { label: "Faturalar", link: "/invoice-report", icon: <Icon.FileText />, showSubRoute: false, submenu: false },
  //     { label: "Satış İadeleri", link: "/sales-returns", icon: <Icon.Copy />, showSubRoute: false, submenu: false },
  //     { label: "Teklif", link: "/quotation-list", icon: <Icon.Save />, showSubRoute: false, submenu: false },
  //     { label: "POS", link: "/pos", icon: <Icon.HardDrive />, showSubRoute: false, submenu: false }
  //   ]
  // },
  // {
  //   label: "Promosyonlar",
  //   submenuOpen: true,
  //   submenuHdr: "Promosyonlar",
  //   showSubRoute: false,
  //   submenuItems: [
  //     { label: "Kuponlar", link: "/coupons", icon: <Icon.ShoppingCart />, showSubRoute: false, submenu: false }
  //   ]
  // },
  // {
  //   label: "Satın Alımlar",
  //   submenuOpen: true,
  //   submenuHdr: "Satın Alımlar",
  //   showSubRoute: false,
  //   submenuItems: [
  //     { label: "Satın Alımlar", link: "/purchase-list", icon: <Icon.ShoppingBag />, showSubRoute: false, submenu: false },
  //     { label: "Satın Alma Listesi", link: "/purchase-order-report", icon: <Icon.FileMinus />, showSubRoute: false, submenu: false },
  //     { label: "Satın Alma İadesi", link: "/purchase-returns", icon: <Icon.RefreshCw />, showSubRoute: false, submenu: false }
  //   ]
  // },
 
  {
    label: "Müşteri Yönetimi",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Müşteri Yönetimi",
    submenuItems: [
      { label: "Müşteriler", link: "/customers", icon: <Icon.User />, showSubRoute: false, submenu: false },
      { label: "Tedarikçiler", link: "/suppliers", icon: <Icon.Users />, showSubRoute: false, submenu: false },
      { label: "Mağazalar", link: "/store-list", icon: <Icon.Home />, showSubRoute: false, submenu: false },
      { label: "Depolar", link: "/warehouse", icon: <Icon.Archive />, showSubRoute: false, submenu: false }
    ]
  },
 
  {
    label: "Raporlar",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Raporlar",
    submenuItems: [
      { label: "Satış Raporu", link: "/sales-report", icon: <Icon.BarChart2 />, showSubRoute: false },
      { label: "Satın Alma Raporu", link: "/purchase-report", icon: <Icon.PieChart />, showSubRoute: false },
      { label: "Envanter Raporu", link: "/inventory-report", icon: <Icon.Inbox />, showSubRoute: false },
      { label: "Fatura Raporu", link: "/invoice-report", icon: <Icon.File />, showSubRoute: false },
      { label: "Tedarikçi Raporu", link: "/supplier-report", icon: <Icon.UserCheck />, showSubRoute: false },
      { label: "Müşteri Raporu", link: "/customer-report", icon: <Icon.User />, showSubRoute: false },
      { label: "Gider Raporu", link: "/expense-report", icon: <Icon.FileText />, showSubRoute: false },
      { label: "Gelir Raporu", link: "/income-report", icon: <Icon.BarChart />, showSubRoute: false },
      { label: "Vergi Raporu", link: "/tax-report", icon: <Icon.Database />, showSubRoute: false },
      { label: "Kar & Zarar", link: "/profit-loss-report", icon: <Icon.TrendingDown />, showSubRoute: false }
    ]
  },
  {
    label: "Kullanıcı Yönetimi",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Kullanıcı Yönetimi",
    submenuItems: [
      { label: "Kullanıcılar", link: "/users", icon: <Icon.UserCheck />, showSubRoute: false },
      { label: "Roller & İzinler", link: "/rollistesi", icon: <Icon.UserCheck />, showSubRoute: false },
      { label: "Hesap Silme Talebi", link: "/delete-account", icon: <Icon.Lock />, showSubRoute: false }
    ]
  },
  {
    label: "EBA",
    submenuOpen: true,
    showSubRoute: false,
    submenuItems: [
      { label: "Sertifikalar&Belgeler", link: "/sertifikabelgeler", icon: <Icon.FileText />, showSubRoute: false },
    ]
  },

  // {
  //   label: "Sayfalar",
  //   submenuOpen: true,
  //   showSubRoute: false,
  //   submenuHdr: "Sayfalar",
  //   submenuItems: [
  //     { label: "Profil", link: "/profile", icon: <Icon.User />, showSubRoute: false },
  //     {
  //       label: "Kimlik Doğrulama",
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: <Icon.Shield />,
  //       submenuItems: [
  //         {
  //           label: "Giriş Yap",
  //           submenu: true,
  //           showSubRoute: false,
  //           submenuItems: [
  //             { label: "Kapak", link: "/signin", showSubRoute: false },
  //             { label: "İllüstrasyon", link: "/signin-2", showSubRoute: false },
  //             { label: "Temel", link: "/signin-3", showSubRoute: false }
  //           ]
  //         },
  //         {
  //           label: "Kayıt Ol",
  //           submenu: true,
  //           showSubRoute: false,
  //           submenuItems: [
  //             { label: "Kapak", link: "/register", showSubRoute: false },
  //             { label: "İllüstrasyon", link: "/register-2", showSubRoute: false },
  //             { label: "Temel", link: "/register-3", showSubRoute: false }
  //           ]
  //         },
  //         {
  //           label: "Şifremi Unuttum",
  //           submenu: true,
  //           showSubRoute: false,
  //           submenuItems: [
  //             { label: "Kapak", link: "/forgot-password", showSubRoute: false },
  //             { label: "İllüstrasyon", link: "/forgot-password-2", showSubRoute: false },
  //             { label: "Temel", link: "/forgot-password-3", showSubRoute: false }
  //           ]
  //         },
  //         {
  //           label: "Şifre Sıfırla",
  //           submenu: true,
  //           showSubRoute: false,
  //           submenuItems: [
  //             { label: "Kapak", link: "/reset-password", showSubRoute: false },
  //             { label: "İllüstrasyon", link: "/reset-password-2", showSubRoute: false },
  //             { label: "Temel", link: "/reset-password-3", showSubRoute: false }
  //           ]
  //         },
  //         {
  //           label: "E-posta Doğrulama",
  //           submenu: true,
  //           showSubRoute: false,
  //           submenuItems: [
  //             { label: "Kapak", link: "/email-verification", showSubRoute: false },
  //             { label: "İllüstrasyon", link: "/email-verification-2", showSubRoute: false },
  //             { label: "Temel", link: "/email-verification-3", showSubRoute: false }
  //           ]
  //         },
  //         {
  //           label: "2 Adımlı Doğrulama",
  //           submenu: true,
  //           showSubRoute: false,
  //           submenuItems: [
  //             { label: "Kapak", link: "/two-step-verification", showSubRoute: false },
  //             { label: "İllüstrasyon", link: "/two-step-verification-2", showSubRoute: false },
  //             { label: "Temel", link: "/two-step-verification-3", showSubRoute: false }
  //           ]
  //         },
  //         { label: "Kilit Ekranı", link: "/lock-screen", showSubRoute: false }
  //       ]
  //     },
  //     {
  //       label: "Hata Sayfaları",
  //       submenu: true,
  //       showSubRoute: false,
  //       icon: <Icon.FileMinus />,
  //       submenuItems: [
  //         { label: "404 Hata", link: "/error-404", showSubRoute: false },
  //         { label: "500 Hata", link: "/error-500", showSubRoute: false }
  //       ]
  //     },
  //     // {
  //     //   label: "Yerler",
  //     //   submenu: true,
  //     //   showSubRoute: false,
  //     //   icon: <Icon.Map />,
  //     //   submenuItems: [
  //     //     { label: "Ülkeler", link: "countries", showSubRoute: false },
  //     //     { label: "Eyaletler", link: "states", showSubRoute: false }
  //     //   ]
  //     // },
  //     { label: "Boş Sayfa", link: "/blank-page", icon: <Icon.File />, showSubRoute: false },
  //     { label: "Yakında", link: "/coming-soon", icon: <Icon.Send />, showSubRoute: false },
  //     { label: "Bakım Altında", link: "/under-maintenance", icon: <Icon.AlertTriangle />, showSubRoute: false }
  //   ]
  // },
  {
    label: "Ayarlar",
    submenu: true,
    showSubRoute: false,
    submenuHdr: "Ayarlar",
    submenuItems: [
      {
        label: "Genel Ayarlar",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Settings />,
        submenuItems: [
          { label: "Profil", link: "/general-settings" },
          { label: "Güvenlik", link: "/security-settings" },
          { label: "Bildirimler", link: "/notification" },
          { label: "Bağlı Uygulamalar", link: "/connected-apps" }
        ]
      },
      {
        label: "Web Sitesi Ayarları",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Globe />,
        submenuItems: [
          { label: "Sistem Ayarları", link: "/system-settings", showSubRoute: false },
          { label: "Şirket Ayarları", link: "/company-settings", showSubRoute: false },
          { label: "Yerelleştirme", link: "/localization-settings", showSubRoute: false },
          { label: "Önekler", link: "/prefixes", showSubRoute: false },
          { label: "Tercih", link: "/preference", showSubRoute: false },
          { label: "Görünüm", link: "/appearance", showSubRoute: false },
          { label: "Sosyal Kimlik Doğrulama", link: "/social-authentication", showSubRoute: false },
          { label: "Dil", link: "/language-settings", showSubRoute: false }
        ]
      },
      {
        label: "Uygulama Ayarları",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Smartphone />,
        submenuItems: [
          { label: "Fatura", link: "/invoice-settings", showSubRoute: false },
          { label: "Yazıcı", link: "/printer-settings", showSubRoute: false },
          { label: "POS", link: "/pos-settings", showSubRoute: false },
          { label: "Özel Alanlar", link: "/custom-fields", showSubRoute: false }
        ]
      },
      {
        label: "Sistem Ayarları",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Monitor />,
        submenuItems: [
          { label: "E-posta", link: "/email-settings", showSubRoute: false },
          { label: "SMS Ağ Geçitleri", link: "/sms-gateway", showSubRoute: false },
          { label: "OTP", link: "/otp-settings", showSubRoute: false },
          { label: "GDPR Çerezleri", link: "/gdpr-settings", showSubRoute: false }
        ]
      },
      {
        label: "Finansal Ayarlar",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.DollarSign />,
        submenuItems: [
          { label: "Ödeme Ağ Geçidi", link: "/payment-gateway-settings", showSubRoute: false },
          { label: "Banka Hesapları", link: "/bank-settings-grid", showSubRoute: false },
          { label: "Vergi Oranları", link: "/tax-rates", showSubRoute: false },
          { label: "Para Birimleri", link: "/currency-settings", showSubRoute: false }
        ]
      },
      {
        label: "Diğer Ayarlar",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Hexagon />,
        submenuItems: [
          { label: "Depolama", link: "/storage-settings", showSubRoute: false },
          { label: "IP Adresi Yasakla", link: "/ban-ip-address", showSubRoute: false }
        ]
      },
      { label: "Çıkış Yap", link: "/signin", icon: <Icon.LogOut />, showSubRoute: false }
    ]
  },
  {
    label: "UI Arabirimi",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "UI Arabirimi",
    submenuItems: [
      {
        label: "Temel UI",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Layers />,
        submenuItems: [
          { label: "Uyarılar", link: "/ui-alerts", showSubRoute: false },
          { label: "Akordeon", link: "/ui-accordion", showSubRoute: false },
          { label: "Avatar", link: "/ui-avatar", showSubRoute: false },
          { label: "Rozetler", link: "/ui-badges", showSubRoute: false },
          { label: "Kenarlık", link: "/ui-borders", showSubRoute: false },
          { label: "Düğmeler", link: "/ui-buttons", showSubRoute: false },
          { label: "Düğme Grubu", link: "/ui-buttons-group", showSubRoute: false },
          { label: "İçerik Gezintisi", link: "/ui-breadcrumb", showSubRoute: false },
          { label: "Kart", link: "/ui-cards", showSubRoute: false },
          { label: "Döngü", link: "/ui-carousel", showSubRoute: false },
          { label: "Renkler", link: "/ui-colors", showSubRoute: false },
          { label: "Açılır Menüler", link: "/ui-dropdowns", showSubRoute: false },
          { label: "Izgara", link: "/ui-grid", showSubRoute: false },
          { label: "Görseller", link: "/ui-images", showSubRoute: false },
          { label: "Işık Kutusu", link: "/ui-lightbox", showSubRoute: false },
          { label: "Medya", link: "/ui-media", showSubRoute: false },
          { label: "Modallar", link: "/ui-modals", showSubRoute: false },
          { label: "Yanal Düzen", link: "/ui-offcanvas", showSubRoute: false },
          { label: "Sayfalandırma", link: "/ui-pagination", showSubRoute: false },
          { label: "Açılır Balonlar", link: "/ui-popovers", showSubRoute: false },
          { label: "İlerleme Çubuğu", link: "/ui-progress", showSubRoute: false },
          { label: "Yer Tutucular", link: "/ui-placeholders", showSubRoute: false },
          { label: "Kaydırıcı", link: "/ui-rangeslider", showSubRoute: false },
          { label: "Döndürücü", link: "/ui-spinner", showSubRoute: false },
          { label: "Tatlı Uyarılar", link: "/ui-sweetalerts", showSubRoute: false },
          { label: "Sekmeler", link: "/ui-nav-tabs", showSubRoute: false },
          { label: "Kızartmalar", link: "/ui-toasts", showSubRoute: false },
          { label: "Araç İpuçları", link: "/ui-tooltips", showSubRoute: false },
          { label: "Yazı Tipi", link: "/ui-typography", showSubRoute: false },
          { label: "Video", link: "/ui-video", showSubRoute: false }
        ]
      },
      {
        label: "Gelişmiş UI",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Layers />,
        submenuItems: [
          { label: "Kurdele", link: "/ui-ribbon", showSubRoute: false },
          { label: "Pano", link: "/ui-clipboard", showSubRoute: false },
          { label: "Sürükle & Bırak", link: "/ui-drag-drop", showSubRoute: false },
          { label: "Kaydırıcı", link: "/ui-rangeslider", showSubRoute: false },
          { label: "Puanlama", link: "/ui-rating", showSubRoute: false },
          { label: "Metin Düzenleyici", link: "/ui-text-editor", showSubRoute: false },
          { label: "Sayaç", link: "/ui-counter", showSubRoute: false },
          { label: "Kaydırma Çubuğu", link: "/ui-scrollbar", showSubRoute: false },
          { label: "Yapışkan Not", link: "/ui-stickynote", showSubRoute: false },
          { label: "Zaman Çizelgesi", link: "/ui-timeline", showSubRoute: false }
        ]
      },
      {
        label: "Grafikler",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.BarChart2 />,
        submenuItems: [
          { label: "Apex Grafikleri", link: "/chart-apex", showSubRoute: false },
          { label: "Grafik Js", link: "/chart-js", showSubRoute: false }
        ]
      },
      {
        label: "İkonlar",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Database />,
        submenuItems: [
          { label: "Fontawesome İkonları", link: "/icon-fontawesome", showSubRoute: false },
          { label: "Tüy İkonları", link: "/icon-feather", showSubRoute: false },
          { label: "İyonik İkonlar", link: "/icon-ionic", showSubRoute: false },
          { label: "Malzeme İkonları", link: "/icon-material", showSubRoute: false },
          { label: "Pe7 İkonları", link: "/icon-pe7", showSubRoute: false },
          { label: "Basit Satır İkonları", link: "/icon-simpleline", showSubRoute: false },
          { label: "Themify İkonları", link: "/icon-themify", showSubRoute: false },
          { label: "Hava Durumu İkonları", link: "/icon-weather", showSubRoute: false },
          { label: "Typicon İkonları", link: "/icon-typicon", showSubRoute: false },
          { label: "Bayrak İkonları", link: "/icon-flag", showSubRoute: false }
        ]
      },
      {
        label: "Formlar",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Edit />,
        submenuItems: [
          {
            label: "Form Elemanları",
            submenu: true,
            showSubRoute: false,
            submenuItems: [
              { label: "Temel Girişler", link: "/form-basic-inputs", showSubRoute: false },
              { label: "Onay Kutusu & Radyolar", link: "/form-checkbox-radios", showSubRoute: false },
              { label: "Giriş Grupları", link: "/form-input-groups", showSubRoute: false },
              { label: "Izgara & Boşluklar", link: "/form-grid-gutters", showSubRoute: false },
              { label: "Form Seçimi", link: "/form-select", showSubRoute: false },
              { label: "Giriş Maskeleri", link: "/form-mask", showSubRoute: false },
              { label: "Dosya Yüklemeleri", link: "/form-fileupload", showSubRoute: false }
            ]
          },
          {
            label: "Düzenler",
            submenu: true,
            showSubRoute: false,
            submenuItems: [
              { label: "Yatay Form", link: "/form-horizontal" },
              { label: "Dikey Form", link: "/form-vertical" },
              { label: "Yüzen Etiketler", link: "/form-floating-labels" }
            ]
          },
          { label: "Form Doğrulama", link: "/form-validation" },
          { label: "Select2", link: "/form-select2" },
          { label: "Form Sihirbazı", link: "/form-wizard" }
        ]
      },
      {
        label: "Tablolar",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.Columns />,
        submenuItems: [
          { label: "Temel Tablolar", link: "/tables-basic" },
          { label: "Veri Tabloları", link: "/data-tables" }
        ]
      }
    ]
  },
  {
    label: "Yardım",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Yardım",
    submenuItems: [
      { label: "Dokümantasyon", link: "#", icon: <Icon.FileText />, showSubRoute: false },
      { label: "Güncel Değişiklikler", link: "/change-logs", icon: <Icon.UploadCloud />, showSubRoute: false },
      {
        label: "Çok Seviyeli",
        showSubRoute: false,
        submenu: true,
        icon: <Icon.FileMinus />,
        submenuItems: [
          { label: "Seviye 1.1", link: "#", showSubRoute: false },
          {
            label: "Seviye 1.2",
            submenu: true,
            showSubRoute: false,
            submenuItems: [
              { label: "Seviye 2.1", link: "#", showSubRoute: false },
              {
                label: "Seviye 2.2",
                submenu: true,
                showSubRoute: false,
                submenuItems: [
                  { label: "Seviye 3.1", link: "#", showSubRoute: false },
                  { label: "Seviye 3.2", link: "#", showSubRoute: false }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    label: "Mali İşler & Hesaplar",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Mali İşler & Hesaplar",
    submenuItems: [
      {
        label: "Giderler",
        submenu: true,
        showSubRoute: false,
        icon: <Icon.FileText />,
        submenuItems: [
          { label: "Giderler", link: "/expense-list", showSubRoute: false },
          { label: "Gider Kategorisi", link: "/expense-category", showSubRoute: false }
        ]
      }
    ]
  },
]

