import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
// import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "../../core/json/siderbar_data";
import CollapsedSidebar from "./collapsedSidebar";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../environment";

const Sidebar = () => {
  // const SidebarData = useSelector((state) => state.sidebar_data);
  // console.log(sidebarData, "sidebar");
  const userId = localStorage.getItem('userId');

  const Location = useLocation();

  console.log("Location.pathname", Location.pathname);
  const [currentUser, setCurrentUser] = useState(null);
  const [subOpen, setSubopen] = useState("");
  const [subsidebar, setSubsidebar] = useState("");
  const [sidebarData, setSidebarData] = useState([]);
  const toggleSidebar = (title) => {
    if (title == subOpen) {
      setSubopen("");
    } else {
      setSubopen(title);
    }
  };

  const toggleSubsidebar = (subitem) => {
    if (subitem == subsidebar) {
      setSubsidebar("");
    } else {
      setSubsidebar(subitem);
    }
  };
  useEffect(() => {
    const fetchUser = async () => {
      if (userId) {
        const userRef = doc(db, "users", userId);
        try {
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            let data = SidebarData;

            setCurrentUser(docSnap.data());
            console.log(currentUser)
            if (docSnap.data() && docSnap.data().role.label === 'Kullanıcı') {
              data = [];
            } else if (docSnap.data() && docSnap.data().role.label === 'Muhasebe') {
              data = data.filter(item => item.label === "Raporlar" || item.label === "Uygulama");
            } else if (docSnap.data() && docSnap.data().role.label === 'Satın Alma') {
              data = data.filter(item => item.label === "Sipariş Yönetimi" || item.label === "Hızlı Ekle"||item.label==="Müşteri Yönetimi"||item.label==="Stok");
              data.forEach(item => {
                if (item.label === "Sipariş Yönetimi") {
                  item.submenuItems = item.submenuItems.filter(subItem => subItem.label === "Satın Alma");
                }
                if (item.label === "Müşteri Yönetimi") {
                  item.submenuItems = item.submenuItems.filter(subItem => subItem.label === "Tedarikçiler");
                }
                if (item.label === "Müşteri Yönetimi") {
                  item.submenuItems = item.submenuItems.filter(subItem => subItem.label === "Stok Kartı Oluştur" || subItem.label === "Stok Düzenle/Güncelle");
                }
              });

            } else if (docSnap.data() && docSnap.data().role.label === 'Satış') {
              data = data.filter(item => item.label === "Sipariş Yönetimi" || item.label === "Hızlı Ekle" || item.label === "Stok"||item.label=="Müşteri Yönetimi"||item.label === "Üretim Yönetimi");
              data.forEach(item => {
                if (item.label === "Sipariş Yönetimi") {
                  item.submenuItems = item.submenuItems.filter(subItem => subItem.label === "Satış");
                } else if (item.label === "Stok") {
                  item.submenuItems = item.submenuItems.filter(subItem => subItem.label === "Stok Kartı Oluştur" || subItem.label === "Stok Düzenle/Güncelle");
                }
                else if (item.label === "Müşteri Yönetimi") {
                  item.submenuItems = item.submenuItems.filter(subItem => subItem.label === "Tedarikçiler");
                }
              });
            }
            else if (docSnap.data() && docSnap.data().role.label === 'Yönetim') {
              data = data.filter(item => item.label === "Envanter" || item.label === "Sertifikalar&Belgeler" || item.label === "Uygulama" || item.label === "Stok");
            } else if (docSnap.data() && docSnap.data().role.label === 'Admin') {
              data = data.filter(item =>
                item.label === "Stok" ||
                item.label === "Sipariş Yönetimi" ||
                item.label === "Sertifikalar&Belgeler" ||
                item.label === "Uygulama" ||
                item.label === "İnsan Kaynakları" ||
                item.label === "Müşteri Yönetimi" ||
                item.label === "Kullanıcı Yönetimi" ||
                item.label === "Raporlar" ||
                item.label === "Mali İşler & Hesaplar" ||
                item.label === "Envanter"
              );
            } else if (docSnap.data() && docSnap.data().role.label === 'İnsan Kaynakları') {
              data = data.filter(item =>
                item.label === "Stok" ||
                item.label === "Uygulama" ||
                item.label === "İnsan Kaynakları" ||
                item.label === "Sertifikalar&Belgeler" ||
                item.label === "Müşteri Yönetimi" ||
                item.label === "Kullanıcı Yönetimi" ||
                item.label === "Sipariş Yönetimi"
              );
            } else if (docSnap.data() && docSnap.data().role.label === 'Sistem Admini') {
              data = data.filter(item =>
                item.label === "Stok" ||
                item.label === "Sipariş Yönetimi" ||
                item.label === "Sertifikalar&Belgeler" ||
                item.label === "Uygulama" ||
                item.label === "İnsan Kaynakları" ||
                item.label === "Üretim Yönetimi" ||
                item.label === "Hızlı Ekle"||
                item.label === "Müşteri Yönetimi" ||
                item.label === "Kullanıcı Yönetimi" ||
                item.label === "Raporlar" ||
                item.label === "Mali İşler & Hesaplar" ||
                item.label === "Envanter"||
                item.label === "Tablet"

              );
            }
            else if (docSnap.data() && docSnap.data().role.label === 'Tablet') {
              data = data.filter(item =>
                item.label === "Tablet"
                

              );
            }

            setSidebarData(data);
          } else {
            console.log("No such user!");
            setCurrentUser(null);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        console.log("No user ID found in local storage.");
        setCurrentUser(null);
      }
    };

    fetchUser();
  }, [userId]);


  return (
    <div>
      <div style={{ zIndex: 1 }} className="sidebar" id="sidebar">
        <Scrollbars>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                {sidebarData?.map((mainLabel, index) => (
                  <li className="submenu-open" key={index}>
                    <h6 className="submenu-hdr">{mainLabel?.label}</h6>

                    <ul>
                      {mainLabel?.submenuItems?.map((title, i) => {
                        let link_array = [];
                        title?.submenuItems?.map((link) => {
                          link_array?.push(link?.link);
                          if (link?.submenu) {
                            link?.submenuItems?.map((item) => {
                              link_array?.push(item?.link);
                            });
                          }
                          return link_array;
                        });
                        title.links = link_array;
                        return (
                          <>
                            <li className="submenu" key={i}>
                              <Link
                                to={title?.link}
                                onClick={() => toggleSidebar(title?.label)}
                                className={`${subOpen == title?.label ? "subdrop" : ""
                                  } ${title?.links?.includes(Location.pathname)
                                    ? "active"
                                    : ""
                                  }
                            `}
                              >
                                {/* <Grid /> */}
                                {title?.icon}
                                <span>{title?.label}</span>
                                <span
                                  className={title?.submenu ? "menu-arrow" : ""}
                                />
                              </Link>
                              <ul
                                style={{
                                  display:
                                    subOpen == title?.label ? "block" : "none",
                                }}
                              >
                                {title?.submenuItems?.map(
                                  (item, titleIndex) => (
                                    <li
                                      className="submenu submenu-two"
                                      key={titleIndex}
                                    >
                                      {/* {item.lebel} */}
                                      <Link
                                        to={item?.link}
                                        className={
                                          item?.submenuItems
                                            ?.map((link) => link?.link)
                                            .includes(Location.pathname) ||
                                            item?.link == Location.pathname
                                            ? "active"
                                            : ""
                                        }
                                        onClick={() => {
                                          toggleSubsidebar(item?.label);
                                        }}
                                      >
                                        {item?.label}
                                        <span
                                          className={
                                            item?.submenu ? "menu-arrow" : ""
                                          }
                                        />
                                      </Link>
                                      <ul
                                        style={{
                                          display:
                                            subsidebar == item?.label
                                              ? "block"
                                              : "none",
                                        }}
                                      >
                                        {item?.submenuItems?.map(
                                          (items, titleIndex) => (
                                            <li key={titleIndex}>
                                              {/* {item.lebel} */}
                                              <Link
                                                to={items?.link}
                                                className={`${subsidebar == items?.label
                                                    ? "submenu-two subdrop"
                                                    : "submenu-two"
                                                  } ${items?.submenuItems
                                                    ?.map((link) => link.link)
                                                    .includes(
                                                      Location.pathname
                                                    ) ||
                                                    items?.link ==
                                                    Location.pathname
                                                    ? "active"
                                                    : ""
                                                  }`}
                                              >
                                                {items?.label}
                                              </Link>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </li>
                                  )
                                )}
                              </ul>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
      {/* <HorizontalSidebar /> */}
      <CollapsedSidebar />
    </div>
  );
};

export default Sidebar;
