import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { db } from "../../../environment";
import { collection, getDocs, query, where } from "firebase/firestore";
import { all_routes } from "../../../Router/all_routes";


const Signin = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const login = async (event) => {
    event.preventDefault();
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));

    try {
      const querySnapshot = await getDocs(q);
      let userExists = false;

      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        if (userData.password === password) { // Caution: Storing passwords in plain text is not secure
          userExists = true;

          if (userData.emailVerified) { 
            // Assuming 'emailVerified' is a field you manage in your Firestore
            localStorage.setItem('userId',doc.id)
            localStorage.setItem('role',doc.data().role.label)
            localStorage.setItem("user",doc.data().username);
            console.log("",doc.data().role.label)
            if(doc.data().role.label!="Kullanıcı"){
              navigate('/admin-anasayfa',{state:{userId:doc.id,role:doc.data().role.label}})

            }else{
              navigate('/kullanici-anasayfa',{state:{userId:doc.id,role:doc.data().role.label}})

            }
              
            Swal.fire({
              title: "Giriş Başarılı!",
              text: `Hoşgeldiniz ${userData.username || ''}`,
              icon: "success",
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Tamam'
            });
          } else {
            Swal.fire({
              title: 'Giriş Başarısız!',
              text: 'Lütfen onay için yetkili ile görüşünüz.',
              icon: 'error',
              confirmButtonColor: '#d33',
              confirmButtonText: 'Tamam'
            });
          }
        }
      });

      if (!userExists) {
        Swal.fire({
          title: 'Giriş Başarısız!',
          text: 'Yanlış e-posta veya şifre.',
          icon: 'error',
          confirmButtonColor: '#d33',
          confirmButtonText: 'Tamam'
        });
      }

    } catch (error) {
      Swal.fire({
        title: 'Giriş Başarısız!',
        text: error.message,
        icon: 'error',
        confirmButtonColor: '#d33',
        confirmButtonText: 'Tamam'
      });
    }
  };
  


  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper bg-img">
          <div className="login-content">
            <form onSubmit={login}>
              <div className="login-userset">
                <div className="login-logo logo-normal">
                  <ImageWithBasePath src="assets/img/yclkimya.png" alt="logo" />
                </div>
                <Link className="login-logo logo-white">
  <ImageWithBasePath src="assets/img/logo-white.png" alt="logo" />
</Link>
                
                <div className="login-userheading">
                  <h3>Giriş Yap</h3>
                  <h4>YÜCEL KİMYA ERP SİSTEMİ</h4>
                </div>
                <div className="form-login mb-3">
                  <label className="form-label">E-posta</label>
                  <div className="form-addons">
                  <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
                    <ImageWithBasePath src="assets/img/icons/mail.svg" alt="mail icon" />
                  </div>
                </div>
                <div className="form-login mb-3">
                  <label className="form-label">Şifre</label>
                  <div className="pass-group">
                    <input type="password" className="pass-input form-control" value={password} onChange={e => setPassword(e.target.value)} />
                    <span className="fas toggle-password fa-eye-slash" />
                  </div>
                </div>
                <div className="form-login authentication-check">
                  <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                      <div className="custom-control custom-checkbox">
                        <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                          <input type="checkbox" className="form-control" />
                          <span className="checkmarks" />
                          Beni Hatırla
                        </label>
                      </div>
                      <div className="text-end">
                        <Link className="forgot-link" to={all_routes.forgotPassword}>
                          Şifremi Unuttum
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-login">
                  <button className="btn btn-login">
                    Giriş Yap
                  </button>
                </div>
                
                <div className="form-setlogin or-text">
                  <h4 style={{margin:5}}> YADA </h4>
                </div>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}} >
                  <button onClick={()=>navigate(all_routes.register)} className="btn btn-register">
                    Kayıt Ol
                  </button>
                </div>
                <div className="form-sociallink" style={{display:'flex',width:400,justifyContent:'center'}}>
                  <ul className="d-flex">
                 
                    {/* <li>
                      <Link onClick={loginWithGoogle} to="#">
                        <ImageWithBasePath src="assets/img/icons/google.png" alt="Google" />
                      </Link>
                    </li> */}

                  </ul>
                </div>
              </div>
            </form>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
