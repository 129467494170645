import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, notification, Space, Select } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  doc,
  where,
  query,
} from "firebase/firestore";
import { db } from "../../../environment";

const { confirm } = Modal;
const { Option } = Select;

const AmbalajStokListesi = () => {
  // State for ambalaj data, modal visibility, and form
  const [ambalajList, setAmbalajList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [depolar, setDepolar] = useState([]); // State for depolar

  // Fetch data from Firestore when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      // Fetch the depolar collection
      const depolarSnapshot = await getDocs(collection(db, "depolar"));
      const depolarList = depolarSnapshot.docs.map((doc) => ({
        id: doc.id,
        depoAdi: doc.data().depoAdi,
      }));
      setDepolar(depolarList);

      // Fetch the stocks collection
      const stocksRef = collection(db, "stocks");
      const q = query(
        stocksRef,
        where("StockName", ">=", "PK-"),
        where("StockName", "<=", "PK-\uf8ff")
      );

      const stocksSnapshot = await getDocs(q);
      const stocksList = stocksSnapshot.docs.map((doc) => {
        const stockData = doc.data();
        // Match Warehouse with depoId in depolar collection
        const depo = depolarList.find((depo) => depo.id === stockData.Warehouse);
        return {
          id: doc.id,
          ...stockData,
          depoAdi: depo ? depo.depoAdi : "Depo Adı Yok", // Set depoAdi or default if no match
        };
      });

      setAmbalajList(stocksList);
    };

    fetchData();
  }, []);

  // Show notification
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  // Handle form submission for adding or updating ambalaj
  const handleSubmit = async (values) => {
    if (isEditing) {
      // Update the existing ambalaj
      const ambalajDoc = doc(db, "tumAmbalajlar", currentId);
      await updateDoc(ambalajDoc, {
        ...values,
        Warehouse: values.Warehouse, // Store the Warehouse ID instead of depoAdi
      });
      openNotificationWithIcon("success", "Başarılı!", "Ambalaj güncellendi.");
    } else {
      // Add a new ambalaj
      await addDoc(collection(db, "tumAmbalajlar"), values);
      openNotificationWithIcon("success", "Başarılı!", "Yeni ambalaj eklendi.");
    }

    // Close modal, reset form, and fetch data again
    setIsModalVisible(false);
    form.resetFields();
    setIsEditing(false);
    setCurrentId(null);

    const ambalajlarSnapshot = await getDocs(collection(db, "tumAmbalajlar"));
    const ambalajlarList = ambalajlarSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setAmbalajList(ambalajlarList);
  };

  // Handle deletion of ambalaj
  const handleDelete = async (id) => {
    confirm({
      title: "Silmek istediğinize emin misiniz?",
      onOk: async () => {
        await deleteDoc(doc(db, "tumAmbalajlar", id));
        const updatedAmbalajlar = ambalajList.filter(
          (ambalaj) => ambalaj.id !== id
        );
        setAmbalajList(updatedAmbalajlar);
        openNotificationWithIcon("success", "Başarılı!", "Ambalaj silindi.");
      },
    });
  };

  // Handle editing ambalaj
  const handleEdit = (record) => {
    setIsEditing(true);
    setIsModalVisible(true);
    // Convert Warehouse ID to depoAdi
    const depo = depolar.find((depo) => depo.id === record.Warehouse);
    const formattedRecord = {
      ...record,
      Warehouse: depo ? depo.id : null,
    };
    form.setFieldsValue(formattedRecord);
    setCurrentId(record.id);
  };

  // Show modal for adding/editing
  // const showModal = () => {
  //   form.resetFields();
  //   setIsEditing(false);
  //   setIsModalVisible(true);
  // };

  // Ant Design table columns
  const columns = [
    {
      title: "Stok Kodu",
      dataIndex: "FullStockCode",
      key: "FullStockCode",
    },
    {
      title: "Stok Adı",
      dataIndex: "StockName",
      key: "StockName",
    },
    {
      title: "Birim",
      dataIndex: "Unit",
      key: "Unit",
    },
    {
      title: "Birim Miktarı",
      dataIndex: "UnitAmount",
      key: "UnitAmount",
    },
    {
      title: "Minimum Stok",
      dataIndex: "MinimumStock",
      key: "MinimumStock",
    },
    {
      title: "Maksimum Stok",
      dataIndex: "MaximumStock",
      key: "MaximumStock",
    },
    {
      title: "Depo Adı",
      dataIndex: "depoAdi", // Display depoAdi instead of Warehouse ID
      key: "depoAdi",
    },
    {
      title: "Yardımcı Birimler",
      dataIndex: "auxiliaryUnits",
      key: "auxiliaryUnits",
      render: (auxiliaryUnits) =>
        auxiliaryUnits
          ? auxiliaryUnits
              .map((unit) => `${unit.amount} ${unit.unit}`)
              .join(", ")
          : "N/A", // Eğer auxiliaryUnits boşsa, "N/A" gösterir
    },
    {
      title: "Aksiyonlar",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            type="primary"
          >
            Düzenle
          </Button>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
            danger
          >
            Sil
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="page-wrapper mt-5">
      <h2>Ambalaj Stok Listesi</h2>
      {/* <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showModal}
        style={{ marginBottom: 16 }}
      >
        Yeni Ambalaj Ekle
      </Button> */}

      {/* Ant Design Table for displaying ambalaj list */}
      <Table columns={columns} dataSource={ambalajList} rowKey="id" />

      {/* Ant Design Modal for adding/editing ambalaj */}
      <Modal
        title={isEditing ? "Ambalaj Düzenle" : "Yeni Ambalaj Ekle"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setIsModalVisible(false)}>
            İptal
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            {isEditing ? "Güncelle" : "Ekle"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="StockName"
            label="Stok Adı"
            rules={[{ required: true, message: "Lütfen stok adını girin!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Unit"
            label="Birim"
            rules={[{ required: true, message: "Lütfen birim girin!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="UnitAmount"
            label="Birim Miktarı"
            rules={[
              { required: true, message: "Lütfen birim miktarını girin!" },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="MinimumStock"
            label="Minimum Stok"
            rules={[
              { required: true, message: "Lütfen minimum stoğu girin!" },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="MaximumStock"
            label="Maksimum Stok"
            rules={[
              { required: true, message: "Lütfen maksimum stoğu girin!" },
            ]}
          >
            <Input type="number" />
          </Form.Item>

          {/* Select for depolar */}
          <Form.Item
            name="Warehouse"
            label="Depo"
            rules={[{ required: true, message: "Lütfen depo seçin!" }]}
          >
            <Select placeholder="Depo Seç">
              {depolar.map((depo) => (
                <Option key={depo.id} value={depo.id}>
                  {depo.depoAdi}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Yardımcı Birimler */}
          <Form.List name="auxiliaryUnits">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "amount"]}
                      fieldKey={[fieldKey, "amount"]}
                      rules={[{ required: true, message: "Miktarı girin" }]}
                    >
                      <Input placeholder="Miktar" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "unit"]}
                      fieldKey={[fieldKey, "unit"]}
                      rules={[{ required: true, message: "Birim girin" }]}
                    >
                      <Input placeholder="Birim" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Yardımcı Birim Ekle
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
};

export default AmbalajStokListesi;
