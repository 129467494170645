import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../environment";
import Swal from "sweetalert2";
import ChangeLogs from "../changelogs/changelogs";
import ShortcutSelector from "../shortcuts/shortcutselector";
// import { SidebarData } from "../../core/json/siderbar_data";
import CustomModal from "../modals/modal";
// import { publicRoutes } from "../../Router/router.link";
// import MyAccordion from "../accordions/MyAccordion";
export default function SpecialUserPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { userId } = location.state || {};
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [shortcuts, setShortcuts] = useState([]);
    // const [showModal,] = useState(false);


    const [modals, setModals] = useState([]);
    const closeModal = (index) => {
        // Filter out the modal that needs to be closed
        setModals(currentModals => currentModals.filter((_, i) => i !== index));
    };
    // const handleAccordionClick = (subItems, label) => {
    //     // Push new modal data into the modals array
    //     setModals(currentModals => [...currentModals, { items: subItems, title: label, showModal: showModal }]);
    // };
    // const renderSubItems = (item, depth = 0) => {
    //     return item.submenuItems.map((subItem, idx) => {
    //         if (subItem.submenu) {
    //             return (
    //                 <button
    //                     className="btn btn-link text-start w-100"
    //                     key={`${depth}-${idx}`}
    //                     onClick={() => handleAccordionClick(subItem.submenuItems, subItem.label)}
    //                 >
    //                     {subItem.icon} {subItem.label}
    //                 </button>
    //             );
    //         }
    //         return (
    //             <a
    //                 href={subItem.link}
    //                 target="_blank"
    //                 rel="noopener noreferrer"
    //                 className="btn btn-link text-start w-100"
    //                 key={`${depth}-${idx}`}
    //             >
    //                 {subItem.icon} {subItem.label}
    //             </a>
    //         );
    //     });
    // };
    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            if (!userId) {
                console.log("No user ID found in local storage.");
                setLoading(false);
                return;
            }
            const userRef = doc(db, "users", userId);
            try {
                const docSnap = await getDoc(userRef);
                if (docSnap.exists()) {
                    setCurrentUser(docSnap.data());
                } else {
                    console.log("No such user!");
                    alertUser();
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                alertUser();
            } finally {
                setLoading(false);
            }
        };
        fetchUser();
    }, [userId]);

    useEffect(() => {
        const savedShortcuts = JSON.parse(localStorage.getItem('userShortcuts')) || [];
        setShortcuts(savedShortcuts);
    }, []);

    const alertUser = () => {
        Swal.fire({
            title: 'Kullanıcı Bulunamadı!',
            text: 'Lütfen tekrar giriş yapınız!',
            icon: 'error',
            confirmButtonText: 'Tamam'
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/signin');
            }
        });
    };

    return (
        <div className="page-wrapper">
            
            {modals.map((modal, index) => (
                <CustomModal
                    key={index}
                    showModal={modal.showModal}
                    setShowModal={() => closeModal(index)}
                    modalContent={modal}
                />
            ))}
            <ChangeLogs />
            {loading ? (
                <div id="global-loader">
                    <div className="whirly-loader"></div>
                </div>
            ) : (
                <div className="content" >
                    <div className="container" >
                        <div className="row">
                            <div className="col-md-12">
                                <div className="bg-dark-gray p-2 rounded-lg m-2">
                                    <h1 className="display-4">Hoş Geldiniz!</h1>
                                    <p className="lead">{!loading && currentUser ? currentUser.username : "Hata!"} olarak giriş yaptınız</p>
                                </div>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-4">
                                <div className="bg-dark-gray p-2 rounded-lg m-2">


                                    <div className="accordion" id="shortcutAccordion">

                                        <div className="accordion-item">

                                            <h2 className="accordion-header" id="headingShortcuts">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseShortcuts" aria-expanded="true" aria-controls="collapseShortcuts">
                                                    <h4><i className="fa fa-sticky-note m-2" aria-hidden="true"></i>
                                                        İşlem Kısayolları</h4>
                                                </button>
                                            </h2>
                                            <div id="collapseShortcuts" className="accordion-collapse collapse show" aria-labelledby="headingShortcuts" data-bs-parent="#shortcutAccordion">
                                                <div className="accordion-body">
                                                    <ShortcutSelector onSave={setShortcuts} />

                                                </div>

                                            </div>
                                            {shortcuts.length > 0 && (
                                                <div className=" d-grid gap-2">
                                                    <div className="d-grid gap-2 justify-content-center">
                                                        {shortcuts.map((shortcut, index) => (
                                                            <button
                                                                style={{ width: 150 }}
                                                                className="btn btn-primary mb-2 m-1"
                                                                key={index}
                                                                onClick={() => navigate(shortcut.value)}
                                                            >
                                                                {shortcut.label}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="bg-dark-gray p-2 rounded-lg m-2">
                                    <h4><i className="fa fa-bell m-2"></i>Bildirimler</h4>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="bg-dark-gray p-2 rounded-lg m-2">
                                {/* <MyAccordion
                publicRoutes={publicRoutes}
                SidebarData={SidebarData}
                setShowModal={setShowModal}
                renderSubItems={renderSubItems}
            /> */}


                                    {/* {showModal && (
            <Draggable>
          <div className="modal-content">
            <Modal.Header closeButton onClick={()=>setShowModal(false)}>
              <Modal.Title>{modalContent && modalContent.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalContent &&
          modalContent.items.map((item, index) => (
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-link text-start w-100"
              key={index}
            >
              {item.icon} {item.label}
            </a>
          ))}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" >Kapat</Button>
            </Modal.Footer>
          </div>
      </Draggable>
)} */}
                                    {/* <Modal
  show={showModal}
  onHide={() => setShowModal(false)}
  backdrop={false}
>
  <Draggable handle=".modal-header">
    <div className="modal-content">
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title>{modalContent && modalContent.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalContent &&
          modalContent.items.map((item, index) => (
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-link text-start w-100"
              key={index}
            >
              {item.icon} {item.label}
            </a>
          ))}
      </Modal.Body>
    </div>
  </Draggable>
</Modal> */}





                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
