// import React, { useEffect, useState } from "react";
// import Breadcrumbs from "../../../core/breadcrumbs";
// import Select from "react-select";
// import { Link } from "react-router-dom";
// import "react-datepicker/dist/react-datepicker.css";
// import { Edit, Trash2 } from "react-feather";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import Table from "../../../core/pagination/datatable";
// // import StockadjustmentModal from "../../core/modals/stocks/stockadjustmentModal";
// import HammaddeEkle from "./hammaddeekle";
// import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";
// import { db } from "../../../environment";
// import { Button, Modal } from "react-bootstrap";
// import HammaddeDuzenle from "./hammaddeduzenle";
// import AddMaterialCategory from "./hammaddekategoriekle";
// import { DatePicker } from "antd";
// import Breadcrumbs2 from "../../breadcrumbs2";
// import * as XLSX from 'xlsx';

// const HammaddeStokListesi = () => {
  
//   const [selectedDateForFilter, setSelectedDateForFilter] = useState(null);

//   const [showModal, setShowModal] = useState(false);
//   const [currentId, setCurrentId] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredData, setFilteredData] = useState([]);
  
//   const [data, setData] = useState([])
//   const options = [
//     { value: 'ascending', label: 'Artan' },
//     { value: 'descending', label: 'Azalan' }
//   ];
//   const handleSortChange = (selectedOption) => {
//     if (selectedOption.value === 'ascending') {
//       const sortedData = [...filteredData].sort((a, b) => new Date(a.ShelfLife) - new Date(b.ShelfLife));
//       setFilteredData(sortedData);
//     } else if (selectedOption.value === 'descending') {
//       const sortedData = [...filteredData].sort((a, b) => new Date(b.ShelfLife) - new Date(a.ShelfLife));
//       setFilteredData(sortedData);
//     }
//   };
//   const handleDateChangeForFilter = (date) => {
//     setSelectedDateForFilter(date)
//     if (date) {
//       const formattedDate = date.format('DD/MM/YYYY'); // 'dd/MM/yyyy' formatına çevir
//       const filtered = data.filter(item => {
//         return item.ShelfLife === formattedDate;
//       });
//       setFilteredData(filtered);
//     } else {
//       setFilteredData(data); // Tarih sıfırlanırsa tüm verileri tekrar yükle
//     }
//   };
  
//   useEffect(() => {
//     const unsubscribe = onSnapshot(collection(db, "materials"), (snapshot) => {
//       const materials = [];
//       snapshot.forEach(doc => {
//         materials.push({ id: doc.id, ...doc.data() });
//       });
//       setData(materials);
//       setFilteredData(materials)
//     });
//     return () => unsubscribe();

//   }, []);


  
  
//   const openModal = (id) => {
//     setShowModal(true);
//     setCurrentId(id)
//   };

//   const closeModal = () => {
//     setShowModal(false);
//   };
  

  
//   const columns = [
//     {
//       title: "Hammadde Kodu",
//       dataIndex: "MaterialCode",
//       sorter: (a, b) => (a.MaterialCode || "").localeCompare(b.MaterialCode || "")
//     },
//     {
//       title: "Hammadde Adı",
//       dataIndex: "MaterialName",
//       sorter: (a, b) => (a.MaterialName || "").localeCompare(b.MaterialName || "")
//     },
//     {
//       title: "Hammadde Tipi/Kategorisi",
//       dataIndex: "MaterialCategory",
//       sorter: (a, b) => (a.MaterialCategory || "").localeCompare(b.MaterialCategory || "")
//     },
//     {
//       title: "Birim",
//       dataIndex: "Unit",
//       sorter: (a, b) => (a.Unit || "").localeCompare(b.Unit || "")
//     },
//     {
//       title: "Mevcut Stok Miktarı",
//       dataIndex: "CurrentStock",
//       sorter: (a, b) => a.CurrentStock - b.CurrentStock
//     },
//     {
//       title: "Minimum Stok Miktarı",
//       dataIndex: "MinimumStock",
//       sorter: (a, b) => a.MinimumStock - b.MinimumStock
//     },
//     {
//       title: "Maksimum Stok Miktarı",
//       dataIndex: "MaximumStock",
//       sorter: (a, b) => a.MaximumStock - b.MaximumStock
//     },
//     {
//       title: "Güvenlik Stok Seviyesi",
//       dataIndex: "SafetyStock",
//       sorter: (a, b) => a.SafetyStock - b.SafetyStock
//     },
//     {
//       title: "Standart Maliyet",
//       dataIndex: "StandardCost",
//       sorter: (a, b) => a.StandardCost - b.StandardCost
//     },
//     {
//       title: "Tedarikçi Bilgileri",
//       dataIndex: "SupplierInfo",
//       sorter: (a, b) => (a.SupplierInfo || "").localeCompare(b.SupplierInfo || "")
//     },
//     {
//       title: "Yeniden Sipariş Süresi",
//       dataIndex: "LeadTime",
//       sorter: (a, b) => a.LeadTime - b.LeadTime
//     },
//     {
//       title: "Raf Ömrü",
//       dataIndex: "ShelfLife",
//       sorter: (a, b) => a.ShelfLife - b.ShelfLife
//     },
//     {
//       title: "Depolama Koşulları",
//       dataIndex: "StorageConditions",
//       sorter: (a, b) => (a.StorageConditions || "").localeCompare(b.StorageConditions || "")
//     },
//     {
//       title: "Parti/Seri Numarası",
//       dataIndex: "BatchNumber",
//       sorter: (a, b) => (a.BatchNumber || "").localeCompare(b.BatchNumber || "")
//     },
//     {
//       title: "Sertifikalar ve Uygunluk Belgeleri",
//       dataIndex: "Certificates",
//       sorter: (a, b) => (a.Certificates || "").localeCompare(b.Certificates || "")
//     },
//     {
//       title: "İthalat ve İhracat Bilgileri",
//       dataIndex: "ImportExportInfo",
//       sorter: (a, b) => (a.ImportExportInfo || "").localeCompare(b.ImportExportInfo || "")
//     },
//     {
//       title: "Güvenlik Bilgileri",
//       dataIndex: "SafetyInfo",
//       sorter: (a, b) => (a.SafetyInfo || "").localeCompare(b.SafetyInfo || "")
//     },
//     {
//       title: " ",
//       dataIndex: "action",
//       render: (text, record) => (
//         <td className="action-table-data">
//           <div className="edit-delete-action">
//             <Link className="me-2 p-2" data-id={record.id} to="#" onClick={() => openModal(record.id)}>
//               <Edit className="feather-edit" />
//             </Link>
//             <Link className="confirm-text p-2" to="#" onClick={() => showConfirmationAlert(record)}>
//               <Trash2 className="feather-trash-2" />
//             </Link>
//           </div>
//         </td>
//       )
//     }
//   ];
  

//   const MySwal = withReactContent(Swal);

//   const showConfirmationAlert = (res) => {
//     MySwal.fire({
//       title: "Emin misiniz?",
//       text: "Bu işlem geri alınamayacaktır!",
//       showCancelButton: true,
//       confirmButtonColor: "#00ff00",
//       confirmButtonText: "Evet, sil!",
//       cancelButtonColor: "#ff0000",
//       cancelButtonText: "İptal",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         MySwal.fire({
//           title: "Silindi!",
//           text: "Kayıt Silindi",
//           className: "btn btn-success",
//           confirmButtonText: "Tamam",
//           customClass: {
//             confirmButton: "btn btn-success",
//           },
//         });
//        deleteDoc(doc(db, "materials", res.id));
//       } else {
//         MySwal.close();
//       }
//     });
//   };
  
//   const handleSearch = (event) => {
//     const value = event.target.value.toLowerCase();
//     setSearchTerm(value);
  
 
//       const filtered = data.filter(item => 
//         (item.MaterialName && item.MaterialName.toLowerCase().includes(value)) ||
//         (item.MaterialCode && item.MaterialCode.toLowerCase().includes(value)) ||
//         (item.SupplierInfo && item.SupplierInfo.toLowerCase().includes(value)) ||
//         (item.MaterialCategory && item.MaterialCategory.toLowerCase().includes(value)) ||
//         (item.Unit && item.Unit.toLowerCase().includes(value)) ||
//         (item.CurrentStock && item.CurrentStock.toString().toLowerCase().includes(value)) ||
//         (item.MinimumStock && item.MinimumStock.toString().toLowerCase().includes(value)) ||
//         (item.MaximumStock && item.MaximumStock.toString().toLowerCase().includes(value)) ||
//         (item.SafetyStock && item.SafetyStock.toString().toLowerCase().includes(value)) ||
//         (item.StandardCost && item.StandardCost.toString().toLowerCase().includes(value)) ||
//         (item.LeadTime && item.LeadTime.toLowerCase().includes(value)) ||
//         (item.ShelfLife && item.ShelfLife.toLowerCase().includes(value)) ||
//         (item.StorageConditions && item.StorageConditions.toLowerCase().includes(value)) ||
//         (item.BatchNumber && item.BatchNumber.toLowerCase().includes(value)) ||
//         (item.Certificates && item.Certificates.toLowerCase().includes(value)) ||
//         (item.ImportExportInfo && item.ImportExportInfo.toLowerCase().includes(value)) ||
//         (item.SafetyInfo && item.SafetyInfo.toLowerCase().includes(value))
//       );
//       setFilteredData(filtered);
//     }
  
//   return (
//     <div className="page-wrapper">
//       <div className="content">
//         <Breadcrumbs
//           maintitle="Hammadde Stok Listesi"
//           subtitle=" Hammadde stok listenizi yönetin."
//           addButton="Yeni Ekle"
//         />
//         <Breadcrumbs2
//         buttons={[
          
//           {
//             tooltip: "Excell Çıktısı al",
//             iconSrc: "assets/img/icons/excel.svg",
//             action: () => {
//               const worksheet = XLSX.utils.json_to_sheet(data);
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
//   XLSX.writeFile(workbook, `${"hammaddeler"}.xlsx`);
//             },
//             altText: "Excel"
//           },
//           // Diğer butonlarınızı benzer şekilde ekleyin
//         ]}
        
//         />
//         <Button onClick={()=>{setCurrentId(null),setShowModal(true)}} className="mt-2 mb-2">Hammadde Kategorisi Ekle</Button>
//         {/* /product list */}
//         <div className="card table-list-card">
//           <div className="card-body">
//             <div className="table-top">
//               <div className="search-set">
//                 <div className="search-input">
//                   <input
//                   value={searchTerm}
//                   onChange={handleSearch}
//                     type="text"
//                     placeholder="Ara"
//                     className="form-control form-control-sm formsearch"
//                   />
//                   <Link to className="btn btn-searchset">
//                     <i data-feather="search" className="feather-search" />
//                   </Link>
//                 </div>
//               </div>
              
//               <div className="form-sort stylewidth mb-4">
//                 <DatePicker
//   selected={selectedDateForFilter}
//   onChange={handleDateChangeForFilter}
//   dateFormat="dd/MM/yyyy"
//   placeholderText="Tarih Seç"
//   className="datetimepicker"
// />
// <div style={{width:5,height:5}}></div>
//                 <Select
//   className="select "
//   options={options}
//   placeholder="Sırala"
//   onChange={handleSortChange}
// />
//               </div>
//             </div>
//             {/* /Filter */}
            
//               <div className="card-body pb-0">
//                 <div className="row">
                  
                 
//                   <div className="col-lg-2 col-sm-6 col-12">
                    
//                     <Modal centered show={showModal} onHide={closeModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>{currentId!=null?"Hammadde Düzenle":"Hammadde Kategori Ekle"}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//            {currentId!=null?<HammaddeDuzenle materialId={currentId} />
// :<AddMaterialCategory/>}
//         </Modal.Body>
//         <Modal.Footer>
          
          
//         </Modal.Footer>
//       </Modal>
//                   </div>
                 
                 
//                 </div>
//               </div>
//             {/* /Filter */}
//             <div className="table-responsive">
//               <Table
//                 className="table datanew"
//                 columns={columns}
//                 dataSource={filteredData}
//               />
//             </div>
//           </div>
//         </div>
//         {/* /product list */}
//       </div>
//       <HammaddeEkle />
//     </div>
//   );
// };

// export default HammaddeStokListesi;

import React, { useEffect, useState } from 'react';
import { Table, Spin, Input, Select } from 'antd';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../environment';

const { Option } = Select;

function Hammaddestoklistesi() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [depots, setDepots] = useState([]); // Depo listesini tutar
  const [selectedDepot, setSelectedDepot] = useState('Hepsi'); // Seçilen depo

  useEffect(() => {
    const fetchDepots = async () => {
      try {
        const depotsSnapshot = await getDocs(collection(db, 'depolar'));
        const depotsList = depotsSnapshot.docs.map(doc => ({
          value: doc.id,
          label: doc.data().depoAdi
        }));
        setDepots([{ value: 'Hepsi', label: 'Hepsi' }, ...depotsList]);
      } catch (error) {
        console.error('Depolar getirilirken hata oluştu:', error);
      }
    };

    const fetchHammaddeler = async () => {
      try {
        const hammaddelerQuery = query(collection(db, 'stocks'), where('FullStockCode', '>=', '150'), where('FullStockCode', '<', '151'));
        const querySnapshot = await getDocs(hammaddelerQuery);

        const hammaddelerList = querySnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));

        setData(hammaddelerList);
        setFilteredData(hammaddelerList); // Başlangıçta tüm veriler gösterilir
        setLoading(false);
      } catch (error) {
        console.error('Veriler getirilirken hata oluştu:', error);
        setLoading(false);
      }
    };

    fetchDepots();
    fetchHammaddeler();
  }, []);

  // Arama kutusunun veriyi filtrelemesi için
  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = data.filter((item) =>
      item.StockName.toLowerCase().includes(value.toLowerCase()) ||
      item.FullStockCode.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  // Depo seçimi yapıldığında filtreleme işlemi
  const handleDepotChange = (value) => {
    setSelectedDepot(value);

    if (value === 'Hepsi') {
      setFilteredData(data); // Tüm stokları göster
    } else {
      const filteredByDepot = data.filter(item => item.Warehouse === value);
      setFilteredData(filteredByDepot);
    }
  };

  const columns = [
    {
      title: "Stok Kodu",
      dataIndex: "FullStockCode",
      sorter: (a, b) => (a.FullStockCode || "").localeCompare(b.FullStockCode || ""),
      align: 'right',
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.FullStockCode}
        </span>
      ),
    },
    {
      title: "Stok Adı",
      dataIndex: "StockName",
      sorter: (a, b) => (a.StockName || "").localeCompare(b.StockName || ""),
      align: 'right',
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.StockName}
        </span>
      ),
    },
    {
      title: "Mevcut Stok Miktarı",
      dataIndex: "UnitAmount",
      align: 'right',
      sorter: (a, b) => a.UnitAmount - b.UnitAmount,
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.UnitAmount}
        </span>
      ),
    },
    {
      title: "Birim",
      dataIndex: "Unit",
      sorter: (a, b) => (a.Unit || "").localeCompare(b.Unit || ""),
      align: 'right',
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.Unit}
        </span>
      ),
    },
    {
      title: "Minimum Stok Miktarı",
      dataIndex: "MinimumStock",
      align: 'right',
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.MinimumStock}
        </span>
      ),
      sorter: (a, b) => a.MinimumStock - b.MinimumStock,
    },
    {
      title: "Maksimum Stok Miktarı",
      dataIndex: "MaximumStock",
      align: 'right',
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.MaximumStock}
        </span>
      ),
      sorter: (a, b) => a.MaximumStock - b.MaximumStock,
    },
    {
      title: "Güvenlik Stok Seviyesi",
      dataIndex: "SafetyStock",
      align: 'right',
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.SafetyStock}
        </span>
      ),
      sorter: (a, b) => a.SafetyStock - b.SafetyStock,
    },
    {
      title: "Standart Maliyet",
      dataIndex: "StandardCost",
      align: 'right',
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.StandardCost}
        </span>
      ),
      sorter: (a, b) => a.StandardCost - b.StandardCost,
    },
  ];

  return (
    <div>
      {loading ? (
        <Spin tip="Yükleniyor..." />
      ) : (
        <div className="page-wrapper mt-5">
          {/* Arama Kutusu ve Bilgilendirme Yazısı */}
          <div className="mb-3">
            <Input.Search
              placeholder="Stok kodu veya stok adı ara"
              onSearch={handleSearch}
              enterButton
              style={{ width: '25%' }}
              allowClear
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <div className="mt-2">
              <strong>Yeşiller yeni depo, maviler fabrika verileridir.</strong>
            </div>
            <div className="mt-2">
              <Select
                style={{ width: '25%' }}
                value={selectedDepot}
                onChange={handleDepotChange}
              >
                {depots.map((depo) => (
                  <Option key={depo.value} value={depo.value}>
                    {depo.label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          {/* Tablo */}
          <Table columns={columns} dataSource={filteredData} pagination={{ pageSize: 25 }} />
        </div>
      )}
    </div>
  );
}

export default Hammaddestoklistesi;




