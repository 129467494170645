import React from 'react';
import Draggable from 'react-draggable';
import { Modal, Button } from 'react-bootstrap';
import './styles.css';
import PropTypes from 'prop-types';

const CustomModal = ({ showModal, setShowModal, modalContent }) => {
    // showModal false ise null döner, böylece modal gösterilmez
    if (!showModal) return null;

    return (
        <div className='custom-modal'>
        <Draggable handle=".modal-header">
            <div  className="modal-dialog">
                <div className="modal-content">
                    <Modal.Header>

                        <Modal.Title>{modalContent && modalContent.title}</Modal.Title>
                        <Button variant="primary" onClick={() => setShowModal(false)}>X</Button>

                    </Modal.Header>
                    <Modal.Body>
                        <ul className="list-group">
                            {modalContent && modalContent.items.map((item, index) => (
                                <li className="list-group-item" key={index}>
                                    <a
                                        href={item.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-link text-start w-100"
                                    >
                                        {item.icon} {item.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>Kapat</Button>
                    </Modal.Footer>
                </div>
            </div>
        </Draggable>
        </div>
    );
};

CustomModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    modalContent: PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape({
            link: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            icon: PropTypes.node
        })).isRequired
    })
};

export default CustomModal;
