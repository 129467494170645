import React, { useEffect, useState } from 'react';
import { Table, Spin, Input, Select } from 'antd';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../environment';

const { Option } = Select;

function Mamulstoklistesi() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [depots, setDepots] = useState([]); // Depo listesini tutar
  const [selectedDepot, setSelectedDepot] = useState('Hepsi'); // Seçilen depo

  useEffect(() => {
    const fetchDepots = async () => {
      try {
        const depotsSnapshot = await getDocs(collection(db, 'depolar'));
        const depotsList = depotsSnapshot.docs.map(doc => ({
          value: doc.id,
          label: doc.data().depoAdi
        }));
        setDepots([{ value: 'Hepsi', label: 'Hepsi' }, ...depotsList]);
      } catch (error) {
        console.error('Depolar getirilirken hata oluştu:', error);
      }
    };

    const fetchMamuller = async () => {
      try {
        // FullStockCode değeri 152 ile başlayan stoklar
        const mamullerQuery = query(collection(db, 'stocks'), where('FullStockCode', '>=', '152'), where('FullStockCode', '<', '153'));
        const querySnapshot = await getDocs(mamullerQuery);

        const mamullerList = querySnapshot.docs.map((doc) => ({
          key: doc.id,
          ...doc.data(),
        }));

        setData(mamullerList);
        setFilteredData(mamullerList); // Başlangıçta tüm veriler gösterilir
        setLoading(false);
      } catch (error) {
        console.error('Veriler getirilirken hata oluştu:', error);
        setLoading(false);
      }
    };

    fetchDepots();
    fetchMamuller();
  }, []);

  // Arama kutusunun veriyi filtrelemesi için
  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = data.filter((item) =>
      item.StockName.toLowerCase().includes(value.toLowerCase()) ||
      item.FullStockCode.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  // Depo seçimi yapıldığında filtreleme işlemi
  const handleDepotChange = (value) => {
    setSelectedDepot(value);

    if (value === 'Hepsi') {
      setFilteredData(data); // Tüm stokları göster
    } else {
      const filteredByDepot = data.filter(item => item.Warehouse === value);
      setFilteredData(filteredByDepot);
    }
  };

  const columns = [
    {
      title: "Stok Kodu",
      dataIndex: "FullStockCode",
      sorter: (a, b) => (a.FullStockCode || "").localeCompare(b.FullStockCode || ""),
      align: 'right',
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.FullStockCode}
        </span>
      ),
    },
    {
      title: "Stok Adı",
      dataIndex: "StockName",
      sorter: (a, b) => (a.StockName || "").localeCompare(b.StockName || ""),
      align: 'right',
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.StockName}
        </span>
      ),
    },
    {
      title: "Mevcut Stok Miktarı",
      dataIndex: "UnitAmount",
      align: 'right',
      sorter: (a, b) => a.UnitAmount - b.UnitAmount,
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.UnitAmount}
        </span>
      ),
    },
    {
      title: "Birim",
      dataIndex: "Unit",
      sorter: (a, b) => (a.Unit || "").localeCompare(b.Unit || ""),
      align: 'right',
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.Unit}
        </span>
      ),
    },
    {
      title: "Minimum Stok Miktarı",
      dataIndex: "MinimumStock",
      align: 'right',
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.MinimumStock}
        </span>
      ),
      sorter: (a, b) => a.MinimumStock - b.MinimumStock,
    },
    {
      title: "Maksimum Stok Miktarı",
      dataIndex: "MaximumStock",
      align: 'right',
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.MaximumStock}
        </span>
      ),
      sorter: (a, b) => a.MaximumStock - b.MaximumStock,
    },
    {
      title: "Güvenlik Stok Seviyesi",
      dataIndex: "SafetyStock",
      align: 'right',
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.SafetyStock}
        </span>
      ),
      sorter: (a, b) => a.SafetyStock - b.SafetyStock,
    },
    {
      title: "Standart Maliyet",
      dataIndex: "StandardCost",
      align: 'right',
      render: (text, record) => (
        <span style={{ color: record.Warehouse === "UCtO2UearK0LHFUdfCbO" ? "green" : "blue" }}>
          {record.StandardCost}
        </span>
      ),
      sorter: (a, b) => a.StandardCost - b.StandardCost,
    },
  ];

  return (
    <div>
      {loading ? (
        <Spin tip="Yükleniyor..." />
      ) : (
        <div className="page-wrapper mt-5">
          {/* Arama Kutusu ve Bilgilendirme Yazısı */}
          <div className="mb-3">
            <Input.Search
              placeholder="Stok kodu veya stok adı ara"
              onSearch={handleSearch}
              enterButton
              style={{ width: '25%' }}
              allowClear
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <div className="mt-2">
              <strong>Yeşiller yeni depo, maviler fabrika verileridir.</strong>
            </div>
            <div className="mt-2">
              <Select
                style={{ width: '25%' }}
                value={selectedDepot}
                onChange={handleDepotChange}
              >
                {depots.map((depo) => (
                  <Option key={depo.value} value={depo.value}>
                    {depo.label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          {/* Tablo */}
          <Table columns={columns} dataSource={filteredData} pagination={{ pageSize: 25 }} />
        </div>
      )}
    </div>
  );
}

export default Mamulstoklistesi;
