import React, { useEffect, useRef, useState } from "react";
import { Form, Select, DatePicker, Button, Modal, Input, Card, Row, Col, Space } from "antd";
import { collection, getDocs, addDoc, doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../../environment";
import { useReactToPrint } from "react-to-print";
import ycllogo from "../../../yclkimya.png";
import { MinusCircleOutlined } from '@ant-design/icons'; // Import Ant Design icon for removing stock

const { Option } = Select;

const TransferForm = () => {
  const [stocks, setStocks] = useState([]);
  const [depolar, setDepolar] = useState([]);
  const [filteredStocks, setFilteredStocks] = useState([]);
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [selectedDepartureWarehouse, setSelectedDepartureWarehouse] = useState(null);
  const [selectedArrivalWarehouse, setSelectedArrivalWarehouse] = useState(null);
  const [caris, setCaris] = useState([]);
  const [selectedCari, setSelectedCari] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const pdfRef = useRef();

  // Fetch collections from Firebase (Firestore)
  useEffect(() => {
    const fetchStocks = async () => {
      const querySnapshot = await getDocs(collection(db, "stocks"));
      setStocks(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const fetchDepolar = async () => {
      const querySnapshot = await getDocs(collection(db, "depolar"));
      setDepolar(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const fetchCaris = async () => {
      const querySnapshot = await getDocs(collection(db, "allCaris"));
      setCaris(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchStocks();
    fetchDepolar();
    fetchCaris();
  }, []);

  // Filter stocks based on selected departure warehouse
  useEffect(() => {
    if (selectedDepartureWarehouse) {
      const filtered = stocks.filter(stock => stock.Warehouse === selectedDepartureWarehouse);
      setFilteredStocks(filtered);
    } else {
      setFilteredStocks([]);
    }
  }, [selectedDepartureWarehouse, stocks]);

  // Handle stock selection and quantities
  const handleStockSelection = (value) => {
    setSelectedStocks(value);
  };

  const handleQuantityChange = (stockId, quantity) => {
    setSelectedQuantities(prev => ({
      ...prev,
      [stockId]: quantity,
    }));
  };

  const handleRemoveStock = (stockId) => {
    setSelectedStocks(prevSelectedStocks => prevSelectedStocks.filter(id => id !== stockId));
    setSelectedQuantities(prevQuantities => {
      const newQuantities = { ...prevQuantities };
      delete newQuantities[stockId]; // Remove the quantity associated with the removed stock
      return newQuantities;
    });
  };

  const handleSubmit = async (values) => {
    // Convert evrakTarihi (from DatePicker) to a JavaScript Date object
    const evrakTarihi = values.evrakTarihi ? values.evrakTarihi.toDate() : null;
  
    // Add transfer data to Firestore
    await addDoc(collection(db, "transfers"), {
      evrakTipi: values.evrakTipi,
      evrakTarihi: evrakTarihi, // Use the converted JavaScript Date object
      odemePlani: values.odemePlani,
      cikisDeposu: selectedDepartureWarehouse,
      varisDeposu: selectedArrivalWarehouse,
      selectedCari,
      selectedStocks: selectedStocks.map(stockId => ({
        stockId,
        quantity: selectedQuantities[stockId] || 0,
      })),
    });
  
    // Update each selected stock's Warehouse and quantity in Firestore
    await Promise.all(
      selectedStocks.map(async (stockId) => {
        const stockRef = doc(db, "stocks", stockId);
    
        // Fetch the current stock data
        const stockSnapshot = await getDoc(stockRef);
        const currentStockData = stockSnapshot.data();
    
        // Calculate the new stock quantity by adding the entered amount
        const currentUnitAmount = currentStockData?.UnitAmount || 0; // Get current quantity or default to 0
        const newUnitAmount = parseFloat(currentUnitAmount) + parseFloat(selectedQuantities[stockId]|| 0); // Add entered amount
    
        // Update the stock in Firestore with the new quantity
        await updateDoc(stockRef, {
          Warehouse: selectedArrivalWarehouse, // Update the Warehouse field
          UnitAmount: newUnitAmount, // Update with the new stock quantity
        });
      })
    );
    
    console.log("Stock transfer completed, warehouses and quantities updated!");
  };
  

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "Stock Transfer",
  });

  return (
    <div className="page-wrapper mt-5">
      <Card title="Depo Transfer Formu" bordered={false}>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item label="Evrak Tipi" name="evrakTipi" rules={[{ required: true, message: "Evrak tipi gerekli" }]}>
                <Select placeholder="Evrak tipi seçin">
                  <Option value="Toptan">Toptan</Option>
                  <Option value="Perakende">Perakende</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Evrak Tarihi" name="evrakTarihi" rules={[{ required: true, message: "Evrak tarihi gerekli" }]}>
                <DatePicker style={{ width: '100%' }} placeholder="Tarih seçin" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Ödeme Planı" name="odemePlani" rules={[{ required: true, message: "Ödeme planı gerekli" }]}>
                <Select placeholder="Ödeme planı seçin">
                  <Option value="Pesin">Peşin</Option>
                  <Option value="Taksitli">Taksitli</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Çıkış Deposu" name="cikisDeposu" rules={[{ required: true, message: "Çıkış deposu gerekli" }]}>
                <Select placeholder="Çıkış deposu seçin" onChange={(value) => setSelectedDepartureWarehouse(value)}>
                  {depolar.map(depo => (
                    <Option key={depo.id} value={depo.id}>
                      {depo.depoAdi}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Varış Deposu" name="varisDeposu" rules={[{ required: true, message: "Varış deposu gerekli" }]}>
                <Select placeholder="Varış deposu seçin" onChange={(value) => setSelectedArrivalWarehouse(value)}>
                  {depolar.map(depo => (
                    <Option key={depo.id} value={depo.id}>
                      {depo.depoAdi}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Cari Seçimi" name="cari" rules={[{ required: true, message: "Cari seçimi gerekli" }]}>
                <Select placeholder="Cari seçin" onChange={(value) => setSelectedCari(value)}>
                  {caris.map(cari => (
                    <Option key={cari.id} value={cari.KartAdi}>
                      {cari.KartAdi}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Stok Seç">
            <Button type="primary" onClick={() => setIsModalVisible(true)}>Stok Seç</Button>
          </Form.Item>

          <Modal
            title="Stok Seçimi"
            visible={isModalVisible}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
          >
            <Select mode="multiple" placeholder="Stok seçin" onChange={handleStockSelection} style={{ width: '100%',height:200 }}>
              {filteredStocks.map((stock) => (
                <Option key={stock.id} value={stock.id}>
                  {stock.StockName}
                </Option>
              ))}
            </Select>
          </Modal>

          {/* Selected Stocks List with Remove Option */}
          <Row gutter={[16, 16]}>
            {selectedStocks.map(stockId => (
              <Col span={12} key={stockId}>
                <Form.Item label={`Miktar (${stocks.find(stock => stock.id === stockId)?.StockName})`}>
                  <Input
                    type="number"
                    placeholder="Miktar girin"
                    value={selectedQuantities[stockId] || ""}
                    onChange={(e) => handleQuantityChange(stockId, e.target.value)}
                  />
                  <Button
                    type="text"
                    icon={<MinusCircleOutlined />}
                    onClick={() => handleRemoveStock(stockId)}
                    style={{ marginTop: "5px", color: "red" }}
                  >
                    Kaldır
                  </Button>
                </Form.Item>
              </Col>
            ))}
          </Row>

          <Form.Item>
            <Space size="middle">
              <Button type="primary" htmlType="submit">Kaydet</Button>
              <Button onClick={handlePrint}>PDF Çıktısı Al</Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>

      {/* PDF Layout */}
      <div ref={pdfRef} style={{ padding: "20px", fontFamily: "Arial, sans-serif", border: "2px solid #ddd", borderRadius: "10px", backgroundColor: "#f9f9f9" }}>
        <div style={{ textAlign: "center", marginBottom: "30px", borderBottom: "2px solid #ccc", paddingBottom: "10px" }}>
          <img src={ycllogo} alt="Company Logo" style={{ width: "150px", marginBottom: "10px" }} />
          <h1 style={{ color: "#333" }}>Depo Transfer Bilgileri</h1>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <h3 style={{ color: "#333", borderBottom: "1px solid #ccc", paddingBottom: "5px" }}>Genel Bilgiler</h3>
          <p><strong>Çıkış Deposu:</strong> {depolar.find(depo => depo.id === selectedDepartureWarehouse)?.depoAdi}</p>
          <p><strong>Varış Deposu:</strong> {depolar.find(depo => depo.id === selectedArrivalWarehouse)?.depoAdi}</p>
          <p><strong>Cari:</strong> {caris.find(cari => cari.KartAdi === selectedCari)?.KartAdi}</p>
          <p><strong>Evrak Tarihi:</strong> {new Date().toLocaleDateString()}</p>
        </div>

        <h3 style={{ color: "#333", borderBottom: "1px solid #ccc", paddingBottom: "5px" }}>Stok Transfer Detayları</h3>
        <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "10px" }}>
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2", textAlign: "left" }}>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Stok Adı</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Miktar</th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>Birim</th>
            </tr>
          </thead>
          <tbody>
            {selectedStocks.map((stockId) => {
              const stock = stocks.find(stock => stock.id === stockId);
              return (
                <tr key={stockId} style={{ textAlign: "left" }}>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{stock?.StockName}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{selectedQuantities[stockId] || 0}</td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>{stock?.Unit || "N/A"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div style={{ marginTop: "40px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ textAlign: "center", width: "45%", borderRight: "1px solid #ccc", paddingRight: "10px" }}>
              <h4>Belgeyi Veren</h4>
              <p>________________________</p>
              <p><strong>İsim Soyisim:</strong> ________________________</p>
              <p><strong>İmza:</strong> ________________________</p>
            </div>

            <div style={{ textAlign: "center", width: "45%", paddingLeft: "10px" }}>
              <h4>Belgeyi Alan</h4>
              <p>________________________</p>
              <p><strong>İsim Soyisim:</strong> ________________________</p>
              <p><strong>İmza:</strong> ________________________</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferForm;
