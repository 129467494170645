import React, { useEffect, useState } from 'react';
import { List, Card, Spin, Collapse, Table, Typography, Button, Input, Form, message } from 'antd';
import { collection, getDocs, doc, updateDoc, addDoc, query, where, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../environment';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PropTypes from 'prop-types';
import './BatchSelectionTable.css';
dayjs.extend(customParseFormat);

const { Panel } = Collapse;
const { Title, Text } = Typography;

// BatchSelectionTable component
function BatchSelectionTable({ totalCells, onCounterUpdate, initialCounter }) {
  const [counter, setCounter] = useState(initialCounter || totalCells); // Start from totalCells

  // Decrease the counter (batchCount)
  const handleDecrement = () => {
    if (counter > 0) {
      Swal.fire({
        title: 'Emin misiniz?',
        text: `Değeri azaltmak istiyor musunuz?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, azalt!',
        cancelButtonText: 'Hayır, iptal et!',
      }).then((result) => {
        if (result.isConfirmed) {
          setCounter((prevCounter) => {
            const newCounter = prevCounter - 1;
            onCounterUpdate(newCounter); // Pass the updated counter to the parent component
            message.success('Değer azaltıldı!');
            return newCounter;
          });
        }
      });
    } else {
      message.warning('Değer 0\'dan küçük olamaz!');
    }
  };

  return (
    <div className="circle-container">
      <div
        className={`circle ${counter <= 0 ? 'red-circle' : ''}`} // Add 'red-circle' class when counter is 0 or less
        onClick={handleDecrement}
      >
        {counter}
      </div>
    </div>
  );
}




// Add PropTypes validation
BatchSelectionTable.propTypes = {
  totalCells: PropTypes.number.isRequired,
  onCounterUpdate: PropTypes.func.isRequired,
  initialCounter: PropTypes.number, // Fix for missing initialCounter validation
};

// TabletUretimKarti component
function TabletUretimKarti() {
  const [uretimKartlari, setUretimKartlari] = useState([]);
  const [selectedKart, setSelectedKart] = useState(null);
  const [loading, setLoading] = useState(true);
  const [producedQuantity, setProducedQuantity] = useState(null);
  const [wasteQuantity, setWasteQuantity] = useState(null);
  const [totalQuantity, setTotalQuantity] = useState(null);
  const [batchCounter, setBatchCounter] = useState(0); // To track the counter from BatchSelectionTable
  const [initialCounter, setInitialCounter] = useState(null); // For loading previously saved counter

  // Yeni inputlar için state ekleyelim
  const [palletCount, setPalletCount] = useState(null);
  const [productsPerPallet, setProductsPerPallet] = useState(null);
  const [remainingStock, setRemainingStock] = useState(null);

  useEffect(() => {
    const fetchUretimKartlari = async () => {
      try {
        const uretimKartlariCollection = collection(db, 'onaylananUretimKartlari');
        const uretimKartlariSnapshot = await getDocs(uretimKartlariCollection);
        const uretimKartlariList = uretimKartlariSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const today = dayjs();
        const tenDaysAgo = today.subtract(30, 'day');

        const groupedByDate = {};
        uretimKartlariList.forEach(kart => {
          const date = dayjs(kart.approval_date, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD');
          if (dayjs(date).isAfter(tenDaysAgo)) {
            if (!groupedByDate[date]) {
              groupedByDate[date] = [];
            }
            groupedByDate[date].push(kart);
          }
        });

        setUretimKartlari(groupedByDate);
      } catch (error) {
        console.error("Veri çekme hatası:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUretimKartlari();
  }, []);

  // Updates total quantity when produced or waste quantity changes
  useEffect(() => {
    setTotalQuantity(parseInt(producedQuantity || 0) );
  }, [producedQuantity, wasteQuantity]);

  // Fetch initial batch counter from Firebase when a card is selected
  useEffect(() => {
    if (selectedKart) {
      const fetchInitialCounter = async () => {
        const kartRef = doc(db, 'onaylananUretimKartlari', selectedKart.id);
        const kartSnapshot = await getDoc(kartRef);
        if (kartSnapshot.exists()) {
          const data = kartSnapshot.data();
          const savedCounter = data.batchCounter || 0;  // Load the counter from Firebase (if it exists)
          setInitialCounter(savedCounter);
          setBatchCounter(savedCounter);  // Start from the saved value
        }
      };
      fetchInitialCounter();
    }
  }, [selectedKart]);

  // Save the updated counter to Firebase
  const updateBatchCounter = async (newCounter) => {
    setBatchCounter(newCounter);
    if (selectedKart) {
      const kartRef = doc(db, 'onaylananUretimKartlari', selectedKart.id);
      await updateDoc(kartRef, { batchCounter: newCounter });
    }
  };

  const handleFinishProduction = async () => {
    try {
        const totalBatches = selectedKart.batchRows * selectedKart.batchColumns;

        // Log all inputs before proceeding
        console.log('Produced Quantity:', producedQuantity);
        console.log('Pallet Count:', palletCount);
        console.log('Products Per Pallet:', productsPerPallet);
        console.log('Remaining Stock:', remainingStock);
        console.log('Waste Quantity:', wasteQuantity);

        // Validate input fields
        if (!palletCount || !productsPerPallet || !remainingStock || !producedQuantity || !wasteQuantity) {
            Swal.fire('Eksik Bilgi!', 'Lütfen tüm gerekli alanları doldurun.', 'error');
            return;
        }

        if (batchCounter < totalBatches) {
          Swal.fire('Eksik Batch Seçimi!', 'Üretimi bitirmek için tüm batchlerin seçili olduğundan emin olun.', 'error');
          return;
        }

        if (!producedQuantity || producedQuantity <= 0) {
            Swal.fire('Üretim Hatası!', 'Lütfen üretimden çıkan ürün adedini girin.', 'error');
            return;
        }

        // Log validation completion
        console.log('Validation passed, starting stock checks.');

        // Step 1: Stock checks for raw materials
        for (const material of selectedKart.rawMaterials) {
            const stockQuery = query(collection(db, 'stocks'), where('StockName', '==', material.materialName));
            const stockSnapshot = await getDocs(stockQuery);

            if (!stockSnapshot.empty) {
                const stockDoc = stockSnapshot.docs[0];
                const stockData = stockDoc.data();
                const updatedUnitAmount = (parseFloat(stockData.UnitAmount) || 0) - parseFloat(material.amount * selectedKart.totalCells);  // Total used amount

                if (updatedUnitAmount < 0) {
                    throw new Error(`Yetersiz stok miktarı: ${material.materialName}`);
                }

                const stockRef = doc(db, 'stocks', stockDoc.id);
                await updateDoc(stockRef, { UnitAmount: updatedUnitAmount });
            } else {
                throw new Error(`Stok bulunamadı: ${material.materialName}`);
            }
        }
        const rawMaterialsUsed = selectedKart.rawMaterials.map((material) => {
          const totalAmount = material.amount * selectedKart.totalCells;
          return {
            ...material,
            totalUsedAmount: totalAmount, // Add the total used amount for each material
          };
        });
        // PK-prefixed stock checks
        const pkStockName = `PK-${selectedKart.product_name}`;
        const pkStockQuery = query(collection(db, 'stocks'), where('StockName', '==', pkStockName));
        const pkStockSnapshot = await getDocs(pkStockQuery);

        let updatedPkUnitAmount;

        if (!pkStockSnapshot.empty) {
            const pkStockDoc = pkStockSnapshot.docs[0];
            const pkStockData = pkStockDoc.data();
            const packagingAndWasteTotal = parseFloat(wasteQuantity);
            updatedPkUnitAmount = (parseFloat(pkStockData.UnitAmount)-parseFloat(packagingAndWasteTotal) || 0) ;

            if (updatedPkUnitAmount < 0) {
                throw new Error(`PK- prefiksi ile başlayan stok için yetersiz stok miktarı: ${selectedKart.product_name}`);
            }

            const pkStockRef = doc(db, 'stocks', pkStockDoc.id);
            await updateDoc(pkStockRef, { UnitAmount: updatedPkUnitAmount });
        } else {
            throw new Error(`PK prefiksi ile başlayan stok bulunamadı: ${pkStockName}`);
        }

        // Main stock update
        const mainStockQuery = query(collection(db, 'stocks'), where('StockName', '==', selectedKart.product_name));
        const mainStockSnapshot = await getDocs(mainStockQuery);

        if (!mainStockSnapshot.empty) {
            const mainStockDoc = mainStockSnapshot.docs[0];
            const mainStockData = mainStockDoc.data();
            const updatedMainUnitAmount = (parseFloat(mainStockData.UnitAmount) || 0) + parseFloat(producedQuantity) + parseFloat(remainingStock);

            const mainStockRef = doc(db, 'stocks', mainStockDoc.id);
            await updateDoc(mainStockRef, { UnitAmount: updatedMainUnitAmount });
        }

        // Step 2: Add the card to 'üretimiBitenKartlar'
        const finishedProductionRef = collection(db, 'üretimiBitenKartlar');
        await addDoc(finishedProductionRef, {
            ...selectedKart,
            production_end_date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            producedQuantity,
            palletCount,
            productsPerPallet,
            remainingStock,
            wasteQuantity,
            totalQuantity,
            rawMaterialsUsed
        });

        // Step 3: Delete the card from 'onaylananUretimKartlari'
        // Step 3: Delete the card from 'onaylananUretimKartlari'
const kartRef = doc(db, 'onaylananUretimKartlari', selectedKart.id);
await deleteDoc(kartRef);  // Use deleteDoc to remove the document from the collection


        Swal.fire('Üretim Tamamlandı!', 'Kart başarıyla üretimi biten kartlar arasına kaydedildi ve stok güncellendi.', 'success');

        // Reset state
        setUretimKartlari(prevKartlari => {
            const updatedKartlari = { ...prevKartlari };
            const dateGroup = updatedKartlari[dayjs(selectedKart.approval_date, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD')];
            updatedKartlari[dayjs(selectedKart.approval_date, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD')] = dateGroup.filter(kart => kart.id !== selectedKart.id);
            return updatedKartlari;
        });

        // Reset other states
        setSelectedKart(null);
        setProducedQuantity(0);
        setWasteQuantity(0);
        setBatchCounter(0);
        setPalletCount(0);
        setProductsPerPallet(0);
        setRemainingStock(0);
    } catch (error) {
        console.error("An error occurred during production:", error);
        Swal.fire('Hata!', error.message, 'error');
    }
};


const rawMaterialsColumns = [
  {
    title: 'Malzeme Adı',
    dataIndex: 'materialName',
    key: 'materialName',
    align:'right'
  },
  {
    title: 'Tip',
    dataIndex: 'type',
    key: 'type',
    align:'right'
  },
  {
    title: 'Miktar (KG)',
    dataIndex: 'amount',
    key: 'amount',
    align:'right'
  },
  {
    title: 'Toplam Kullanılacak Miktar (KG)',
    render: (_, material) => (material.amount * selectedKart.totalCells).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    key: 'totalUsedAmount',
    align:'right'
  },
  {
    title: 'Açıklama',
    dataIndex: 'description',
    key: 'description',
    align:'right'
  },
];

  return (
    <div className='page-wrapper mt-5' style={{ backgroundColor: '#ffffff' }}>
      {loading ? (
        <Spin size="large" />
      ) : (
        <Collapse accordion style={{ marginBottom: '20px' }}>
          {Object.keys(uretimKartlari).length > 0 ? (
            Object.keys(uretimKartlari).map(date => (
              <Panel header={date} key={date}>
                <List
                  grid={{ gutter: 16, column: 4 }}
                  dataSource={uretimKartlari[date]}
                  renderItem={item => (
                    <List.Item>
                      <Card
                        title={<Text strong>{item.card_number}</Text>}
                        onClick={() => setSelectedKart(item)}
                        hoverable
                        style={{
                          border: item.id === selectedKart?.id ? '2px solid #1890ff' : '1px solid #e8e8e8',
                          backgroundColor: item.id === selectedKart?.id ? '#e6f7ff' : '#fff',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                          borderRadius: '8px'
                        }}
                      >
                        <Text>{item.product_name}</Text>
                      </Card>
                    </List.Item>
                  )}
                />
              </Panel>
            ))
          ) : (
            <p>Son 10 gün içinde üretim kartı bulunmamaktadır.</p>
          )}
        </Collapse>
      )}
      {selectedKart && (
        <div className="batch-section" style={{ marginTop: '20px', padding: '20px', backgroundColor: '#f0f2f5', borderRadius: '8px' }}>
          <Title level={4}>Seçili Kart: {selectedKart.card_number}</Title>
          <p><strong>Ürün Adı:</strong> {selectedKart.product_name}</p>
          <p><strong>Üretim Durumu:</strong> {selectedKart.production_status}</p>
          <p><strong>Toplam Üretim Miktarı:</strong> {selectedKart.total_production_amount} KG</p>
          <p><strong>Toplam Üretim Miktarı Adeti:</strong> {selectedKart.total_production_amount_pcs} ADET</p>
          <p><strong>Ambalaj Ağırlığı:</strong> {selectedKart.packaging_weight} KG</p>
          <p><strong>Not:</strong> {selectedKart.notes}</p>

          <h3>Batch Seçimi</h3>
          <BatchSelectionTable
            key={`${selectedKart.id}-${initialCounter}`} // Make sure the component rerenders when initialCounter changes
            totalCells={selectedKart.totalCells} // Virgüllü sayıyı direkt kullanıyoruz
            initialCounter={initialCounter} // Pass the initial counter
            onCounterUpdate={updateBatchCounter} // Update the counter
          />

          <h3>Hammaddeler</h3>
          <Table
            columns={rawMaterialsColumns}
            dataSource={selectedKart.rawMaterials}
            pagination={false}
            bordered
            
            rowKey="materialName"
          />

<div style={{ marginTop: '20px' }}>
  <Form layout="inline" onFinish={handleFinishProduction}>
    <Form.Item
      label="Üretimden Çıkan Ürün Adedi"
      rules={[{ required: true, message: 'Lütfen üretimden çıkan ürün adedini girin!' }]}
    >
      <Input
        value={producedQuantity}
        onChange={(e) => setProducedQuantity(e.target.value)}
        type="number"
        size="small"
        style={{ width: '150px' }}
        required
      />
    </Form.Item>

    <Form.Item
      label="Çıkan Palet Adedi"
      rules={[{ required: true, message: 'Lütfen çıkan palet adedini girin!' }]}
    >
      <Input
        value={palletCount}
        onChange={(e) => setPalletCount(e.target.value)}
        type="number"
        size="small"
        style={{ width: '150px' }}
        required
      />
    </Form.Item>

    <Form.Item
      label="1 Paletteki Ürün Adedi"
      rules={[{ required: true, message: 'Lütfen 1 paletteki ürün adedini girin!' }]}
    >
      <Input
        value={productsPerPallet}
        onChange={(e) => setProductsPerPallet(e.target.value)}
        type="number"
        size="small"
        style={{ width: '150px' }}
        required
      />
    </Form.Item>

    <Form.Item
      label="Stoğa Kalan Ürün Adedi"
      rules={[{ required: true, message: 'Lütfen stoğa kalan ürün adedini girin!' }]}
    >
      <Input
        value={remainingStock}
        onChange={(e) => setRemainingStock(e.target.value)}
        type="number"
        size="small"
        style={{ width: '150px' }}
        required
      />
    </Form.Item>

    <Form.Item
      label="Ambalajdan Çıkan Fire Adedi"
      rules={[{ required: true, message: 'Lütfen ambalajdan çıkan fire adedini girin!' }]}
    >
      <Input
        value={wasteQuantity}
        onChange={(e) => setWasteQuantity(e.target.value)}
        type="number"
        size="small"
        style={{ width: '150px' }}
        required
      />
    </Form.Item>

    <Form.Item label="Toplam Adet">
      <Input
        value={totalQuantity}
        disabled
        size="small"
        style={{ width: '150px' }}
      />
    </Form.Item>

    <Button
  type="primary"
  htmlType="submit"
  style={{ marginTop: '20px', width: '100%' }}
  disabled={batchCounter === null || batchCounter === undefined||batchCounter>0} // Allow zero or negative values
>
  Üretimi Bitir
</Button>

  </Form>
</div>

        </div>
      )}
    </div>
  );
}

export default TabletUretimKarti;
