import React, { useState, useEffect, useRef } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { collection, query, orderBy, limit, getDocs, Timestamp, doc, getDoc, updateDoc, addDoc } from 'firebase/firestore';
import { Form, Input, Button, Row, Col, DatePicker, Select, Modal, Collapse, Typography, message, Card } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import PrintComponent from './utils/printcomponent';
import { db } from '../../environment';
import PendingOrdersTable from './utils/pendingorderstable';
import dayjs from 'dayjs';

const API_KEY = '025f1d2d3e0b4217bfed93c3';
const BASE_URL = 'https://v6.exchangerate-api.com/v6';
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

const PendingOrderForm = () => {
  const { control, handleSubmit, reset, watch, setValue } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'urunler',
  });
  const watchBelgeNo = watch('belgeNo', '');
  const [manualEntry, setManualEntry] = useState(false); 

  const savedLanguage = localStorage.getItem('selectedLanguage') || 'tr';
  const [language, setLanguage] = useState(savedLanguage);
  const componentRef = useRef();
  const [allStockData, setAllStockData] = useState([]);
  const [allCaris, setAllCaris] = useState([]);
  const [exchangeRates, setExchangeRates] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [, setSelectedCari] = useState(null);
  const watchUrunler = watch('urunler', []);
  useEffect(() => {
    const currentValue = watch('sevkYontemi');
    const translatedValue = language === 'tr' ? translateValueToTurkish(currentValue) : translateValueToEnglish(currentValue);
    setValue('sevkYontemi', translatedValue);
  }, [language, watch, setValue]);
  useEffect(() => {
    if (language === 'tr') {
      const currentValue = watch('sevkYontemi');
      if (currentValue === 'Sea Route') setValue('sevkYontemi', 'Deniz Yolu,');
      else if (currentValue === 'Land Route') setValue('sevkYontemi', 'Kara Yolu,');
      else if (currentValue === 'Factory to Warehouse Delivery') setValue('sevkYontemi', 'Fabrikadan depo teslimatı,');
      else if (currentValue === 'Warehouse Delivery') setValue('sevkYontemi', 'Depo teslimatı,');
    } else {
      const currentValue = watch('sevkYontemi');
      if (currentValue === 'Deniz Yolu,') setValue('sevkYontemi', 'Sea Route');
      else if (currentValue === 'Kara Yolu,') setValue('sevkYontemi', 'Land Route');
      else if (currentValue === 'Fabrikadan depo teslimatı,') setValue('sevkYontemi', 'Factory to Warehouse Delivery');
      else if (currentValue === 'Depo teslimatı,') setValue('sevkYontemi', 'Warehouse Delivery');
    }
  }, [language, setValue, watch]);
  useEffect(() => {
    const setInitialBelgeNo = async () => {
      const nextBelgeNo = await generateNextBelgeNo();
      setValue('belgeNo', nextBelgeNo);
    };
    setInitialBelgeNo();
    fetchAllCaris()
    fetchAllStockData();
    fetchExchangeRates();
  }, []);

  useEffect(() => {
    if (selectedOrderId) {
      fetchOrderDetails(selectedOrderId);
    }
  }, [selectedOrderId]);

  const fetchAllStockData = async () => {
    setLoading(true);
    try {
      const stockSnapshot = await getDocs(collection(db, 'stocks'));
      const stockList = stockSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      // Ensure that FullStockCode exists before using startsWith
      const filteredStockList = stockList.filter(stock => stock.FullStockCode && stock.FullStockCode.startsWith('150'));
      
      setAllStockData(filteredStockList);
    } catch (error) {
      console.error("Stok verisi alınamadı: ", error);
    }
    setLoading(false);
  };
  
  const paymentMethods = {
    tr: [
      { value: "nakit", label: "Nakit" },
      { value: "krediKarti", label: "Kredi Kartı" },
      { value: "havale", label: "Havale" },
    ],
    en: [
      { value: "nakit", label: "Cash" },
      { value: "krediKarti", label: "Credit Card" },
      { value: "havale", label: "Wire Transfer" },
    ],
  };

  const invoiceTypes = {
    tr: [
      { value: "İhracat Faturası", label: "İhracat Faturası" },
      { value: "Yurtiçi Satış Faturası", label: "Yurtiçi Satış Faturası" },
      { value: "İhraç Kayıtlı Fatura", label: "İhraç Kayıtlı Fatura" },
    ],
    en: [
      { value: "İhracat Faturası", label: "Export Invoice" },
      { value: "Yurtiçi Satış Faturası", label: "Domestic Sales Invoice" },
      { value: "İhraç Kayıtlı Fatura", label: "Export Registered Invoice" },
    ],
  };
  const translateValueToTurkish = (value) => {
    const translations = {
      'Sea': 'Deniz Yolu',
      'Land': 'Kara Yolu',
      'Factory to Warehouse Delivery': 'Fabrikadan Depo Teslimatı',
      'Warehouse Delivery': 'Depo Teslimatı'
    };
    return translations[value] || value;
  };
  const translateValueToEnglish = (value) => {
    const translations = {
      'Deniz Yolu': 'Sea',
      'Kara Yolu': 'Land',
      'Fabrikadan Depo Teslimatı': 'Factory to Warehouse Delivery',
      'Depo Teslimatı': 'Warehouse Delivery'
    };
    return translations[value] || value;
  };
  const deliveryMethods = {
    tr: [
      { value: 'Deniz Yolu', label: 'Deniz Yolu' },
      { value: 'Kara Yolu', label: 'Kara Yolu' },
      { value: 'Fabrikadan Depo Teslimatı', label: 'Fabrikadan Depo Teslimatı' },
      { value: 'Depo Teslimatı', label: 'Depo Teslimatı' }
    ],
    en: [
      { value: 'Sea', label: 'Sea' },
      { value: 'Land', label: 'Land' },
      { value: 'Factory to Warehouse Delivery', label: 'Factory to Warehouse Delivery' },
      { value: 'Warehouse Delivery', label: 'Warehouse Delivery' }
    ]
  };

  const logisticsCosts = {
    tr: [
      { value: "Satıcıya aittir", label: "Satıcıya aittir" },
      { value: "Alıcıya aittir", label: "Alıcıya aittir" },
    ],
    en: [
      { value: "Satıcıya aittir", label: "Seller Pays" },
      { value: "Alıcıya aittir", label: "Buyer Pays" },
    ],
  };
  const fetchExchangeRates = async () => {
    setLoading(true);
    try {
      const responseUSD = await axios.get(`${BASE_URL}/${API_KEY}/latest/USD`);
      const responseEUR = await axios.get(`${BASE_URL}/${API_KEY}/latest/EUR`);

      const rates = {
        USD: responseUSD.data.conversion_rates.TRY,
        EUR: responseEUR.data.conversion_rates.TRY,
        TRY: 1,
      };
      setExchangeRates(rates);
    } catch (error) {
      console.error('Döviz kuru alınamadı: ', error);
    }
    setLoading(false);
  };

  const handleUrunAdiChange = async (urunAdi, index) => {
    const selectedStock = allStockData.find(stock => stock.StockName === urunAdi);
    if (selectedStock) {
      try {
        const stockManagerSnapshot = await getDocs(collection(db, 'stockManager'));
        const stockManagerData = stockManagerSnapshot.docs.map(doc => doc.data());
        const category = stockManagerData.find(item => item.id === selectedStock.categoryId)?.title || '';
        
        setValue(`urunler[${index}].urunKodu`, selectedStock.FullStockCode);
        setValue(`urunler[${index}].urunKategorisi`, category);
        setValue(`urunler[${index}].birim`, selectedStock.Unit);
        setValue(`urunler[${index}].birimFiyat`, selectedStock.StandardCost);
        setValue(`urunler[${index}].paraBirimi`, selectedStock.Currency);
        setValue(`urunler[${index}].UnitAmount`, selectedStock.UnitAmount);
      } catch (error) {
        console.error("Kategori bilgisi alınamadı: ", error);
      }
    }
  };

  const getTitle = () => {
    if (watchBelgeNo.startsWith('STS')) {
      return language === 'tr' ? 'Proforma Fatura' : 'Proforma Invoice';
    } else if (watchBelgeNo.startsWith('ALS')) {
      return language === 'tr' ? 'Satın Alım Formu' : 'Purchase Form';
    } else if (watchBelgeNo.startsWith('PRF')) {
      return language === 'tr' ? 'Proforma Fatura' : 'Proforma Invoice';
    } else {
      return '';
    }
  };

  const calculatePrices = () => {
    setLoading(true);
    setTimeout(() => {
      watchUrunler.forEach((urun, index) => {
        const birimFiyat = parseFloat(urun.birimFiyat) || 0;
        const miktar = parseFloat(urun.miktar) || 0;
        const kdv = parseFloat(urun.kdv) || 0;
        const iskonto = parseFloat(urun.iskonto) || 0;
        const paraBirimi = urun.paraBirimi;
        const rate = exchangeRates[paraBirimi];

        if (rate) {
          const toplamFiyat = birimFiyat * miktar;
          const iskontoFiyat = iskonto ? toplamFiyat * (1 - iskonto / 100) : toplamFiyat;
          const kdvliFiyat = kdv ? iskontoFiyat * (1 + kdv / 100) : iskontoFiyat;

          let tryTutar, usdTutar, eurTutar;

          if (paraBirimi === 'TRY') {
            tryTutar = kdvliFiyat;
            usdTutar = tryTutar / exchangeRates.USD;
            eurTutar = tryTutar / exchangeRates.EUR;
          } else if (paraBirimi === 'USD') {
            usdTutar = kdvliFiyat;
            tryTutar = usdTutar * exchangeRates.USD;
            eurTutar = tryTutar / exchangeRates.EUR;
          } else if (paraBirimi === 'EUR') {
            eurTutar = kdvliFiyat;
            tryTutar = eurTutar * exchangeRates.EUR;
            usdTutar = tryTutar / exchangeRates.USD;
          }

          setValue(`urunler[${index}].toplamFiyat`, toplamFiyat.toFixed(2));
          setValue(`urunler[${index}].iskontoFiyat`, iskontoFiyat.toFixed(2));
          setValue(`urunler[${index}].kdvliFiyat`, kdvliFiyat.toFixed(2));
          setValue(`urunler[${index}].tryTutar`, tryTutar.toFixed(2));
          setValue(`urunler[${index}].usdTutar`, usdTutar.toFixed(2));
          setValue(`urunler[${index}].eurTutar`, eurTutar.toFixed(2));
          setValue(`urunler[${index}].tutar`, kdvliFiyat.toFixed(2));
        } else {
          console.log(`Döviz kuru bulunamadı: ${paraBirimi}`);
        }
      });
      setLoading(false);
    }, 2000);
  };

  const generateNextBelgeNo = async () => {
    const q = query(collection(db, 'approvedOrdersSTA'), orderBy('belgeNo', 'desc'), limit(1));
    const querySnapshot = await getDocs(q);
    const lastBelgeNo = querySnapshot.docs.length > 0 ? querySnapshot.docs[0].data().belgeNo : '0000';

    const nextBelgeNo = (parseInt(lastBelgeNo.split('-')[1], 10) + 1).toString().padStart(4, '0');
    setValue('belgeNo', `STS-${nextBelgeNo}`);
  };

  const convertToJSDate = (date) => {
    return date ? dayjs(date).toDate() : null;
  };

  const onSubmit = async (data) => {
    try {
      Modal.confirm({
        title: language === 'tr' ? 'Kaydı eklemek istediğinize emin misiniz?' : 'Are you sure you want to add the record?',
        okText: language === 'tr' ? 'Evet' : 'Yes',
        cancelText: language === 'tr' ? 'Hayır' : 'No',
        onOk: async () => {
          setLoading(true);
  
          const formattedData = {
            ...data,
            siparisTarihi: convertToJSDate(data.siparisTarihi),
            uretimTarihi: convertToJSDate(data.uretimTarihi),
            sonKullanmaTarihi: convertToJSDate(data.sonKullanmaTarihi),
            sevkTarihi: convertToJSDate(data.sevkTarihi),
            faturaTarihi: convertToJSDate(data.faturaTarihi),
            belgeTarihi: convertToJSDate(data.belgeTarihi),
            dovizKuru: exchangeRates,
          };
  
          const cleanedData = Object.keys(formattedData).reduce((acc, key) => {
            if (formattedData[key] !== undefined) {
              acc[key] = formattedData[key];
            }
            return acc;
          }, {});
  
          if (selectedOrderId) {
            await updateDoc(doc(db, 'pendingOrdersSTA', selectedOrderId), cleanedData);
            message.success(language === 'tr' ? 'Sipariş başarıyla güncellendi!' : 'Order updated successfully!');
          } else {
            await addDoc(collection(db, 'pendingOrdersSTA'), cleanedData);
            message.success(language === 'tr' ? 'Sipariş başarıyla eklendi!' : 'Order added successfully!');
          }
  
          reset();
        },
      });
    } catch (error) {
      console.error('Sipariş eklenirken hata: ', error);
      message.error(language === 'tr' ? 'Sipariş eklenirken hata oluştu. Lütfen tekrar deneyin.' : 'Error adding order. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const fetchOrderDetails = async (orderId) => {
    setLoading(true);
    try {
      const orderDoc = await getDoc(doc(db, 'pendingOrdersSTA', orderId));
      if (orderDoc.exists()) {
        const orderData = orderDoc.data();
        Object.keys(orderData).forEach((key) => {
          if (orderData[key] instanceof Timestamp) {
            setValue(key, dayjs(orderData[key].toDate()));
          } else {
            setValue(key, orderData[key]);
          }
        });
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error fetching order details: ', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllCaris = async () => {
    try {
      const carisSnapshot = await getDocs(collection(db, 'allCaris'));
      const carisList = carisSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAllCaris(carisList);
    } catch (error) {
      console.error("Cari verisi alınamadı: ", error);
    }
  };

  const handleCariChange = async (cariAdi) => {
    const selectedCari = allCaris.find(cari => cari.KartAdi === cariAdi);

    const handleNullValue = (value) => {
      return value == null ? "" : value;
    };

    if (selectedCari) {
      setValue('cariKodu', handleNullValue(selectedCari.KartKodu));
      setValue('cariAdi', handleNullValue(selectedCari.KartAdi));
      setValue('vergiDairesi', handleNullValue(selectedCari.VergiDairesi));
      setValue('vergiNumarasi', handleNullValue(selectedCari.VergiNo));
      setValue('adres', handleNullValue(selectedCari.Adres));
      setValue('email', handleNullValue(selectedCari.EPosta));
      setValue('muhHesapKodu', handleNullValue(selectedCari.MuhHesapKodu));
      setValue('muhHesapAdi', handleNullValue(selectedCari.MuhHesapAdi));
      setSelectedCari(selectedCari);
    }
  };

 

  return (
    <div className='page-wrapper mt-5'>
      <PendingOrdersTable setSelectedOrderId={setSelectedOrderId} />
      <div >
        <Card style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', padding: '30px', borderRadius: '15px' }}>
          <ReactToPrint
            trigger={() => (
              <Button variant="primary" style={{ marginBottom: '20px' }}>
                {language === 'tr' ? 'Yazdır' : 'Print'}
              </Button>
            )}
            content={() => componentRef.current}
          />
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <Select defaultValue="tr" onChange={(value) => {
  localStorage.setItem('selectedLanguage', value);
              setLanguage(value);
              console.log('Selected language:', value);
            }}>
              <Option value="tr">Türkçe</Option>
              <Option value="en">English</Option>
            </Select>
          </div>
          <Title level={2} className="mb-4" style={{ textAlign: 'center', color: '#1890ff' }}>
            {language === 'tr' ? 'Sipariş Güncelleme Formu' : 'Order Update Form'}
          </Title>

          <Form onFinish={handleSubmit(onSubmit)} layout="vertical">  
            <Collapse defaultActiveKey={['1']} expandIconPosition="right">
              <Collapse.Panel header={language === 'tr' ? 'Müşteri Bilgileri' : 'Customer Information'} key="1">
              <Form.Item label={language === 'tr' ? 'Cari Adı' : 'Customer Name'} required>
  <Controller
    name="cariAdi"
    control={control}
    rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
    render={({ field }) => (
      <>
        <Button 
          type="default"
          onClick={() => setManualEntry(!manualEntry)} // Butona tıklayınca manuel giriş moduna geçiş yapar
          style={{ marginBottom: '10px' }}
        >
          {manualEntry ? (language === 'tr' ? 'Seçim Yap' : 'Choose From List') : (language === 'tr' ? 'Elle Gir' : 'Enter Manually')}
        </Button>

        {manualEntry ? (
          <Input
            {...field}
            placeholder={language === 'tr' ? 'Cari Adı Manuel Girin' : 'Enter Customer Name Manually'}
            size="large"
            onBlur={(e) => handleCariChange(e.target.value)} // Elle girilen ada göre bilgileri günceller
          />
        ) : (
          <Select
            {...field}
            showSearch
            placeholder={language === 'tr' ? 'Cari Adı Seçin' : 'Select Customer Name'}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            size="large"
            allowClear
            onClear={() => field.onChange('')} // Seçim kaldırılırsa cari adını boş yapar
            onSelect={(value) => {
              field.onChange(value);
              handleCariChange(value); // Seçili cari adına göre diğer bilgileri günceller
            }}
          >
            {allCaris.map(cari => (
              <Option key={cari.KartAdi} value={cari.KartAdi}>
                {cari.KartAdi}
              </Option>
            ))}
          </Select>
        )}
      </>
    )}
  />
</Form.Item>
                <Row gutter={16}>
                  <Col span={8}>
                  
                    <Form.Item label={language === 'tr' ? 'Cari Kodu' : 'Customer Code'} required>
                      <Controller
                        name="cariKodu"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                   

                    <Form.Item label="Email">
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                    <Form.Item label={language === 'tr' ? 'Adres' : 'Address'}>
                      <Controller
                        name="adres"
                        control={control}
                        render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Vergi Dairesi' : 'Tax Office'}>
                      <Controller
                        name="vergiDairesi"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                    <Form.Item label={language === 'tr' ? 'Vergi Numarası' : 'Tax Number'}>
                      <Controller
                        name="vergiNumarasi"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                   
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'İlgili Kişi Adı' : 'Contact Person Name'}>
                      <Controller
                        name="ilgiliKisiAdi"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                    <Form.Item label={language === 'tr' ? 'Telefon' : 'Phone'}>
                      <Controller
                        name="telefon"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                    
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
            <div><br/></div>

            <Collapse  expandIconPosition="right">
            <Collapse.Panel header={language === 'tr' ? 'Ürün Bilgileri' : 'Product Information'} key="1">
  {fields.map((field, index) => (
    <div key={field.id} style={{ display: 'flex', flexWrap: 'nowrap', gap: '16px', marginBottom: '16px', alignItems: 'flex-start' }}>
      <div style={{ flex: '1 1 auto', minWidth: '350px' }}>
        <Form.Item label={language === 'tr' ? 'Ürün Adı' : 'Product Name'} required>
          <Controller
            name={`urunler[${index}].urunAdi`}
            control={control}
            rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
            render={({ field }) => (
              <Select
                {...field}
                showSearch
                placeholder={language === 'tr' ? 'Ürün Adı Seçin' : 'Select Product Name'}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                size="large"
                onChange={(value) => {
                  field.onChange(value);
                  handleUrunAdiChange(value, index);
                  calculatePrices(index); // Tutarı güncelle
                }}
              >
                {allStockData.map(stock => (
                  <Option key={stock.StockName} value={stock.StockName}>
                    {stock.StockName}
                  </Option>
                ))}
              </Select>
            )}
          />
        </Form.Item>
      </div>

      <div style={{ flex: '1 1 auto', minWidth: '100px' }}>
        <Form.Item label={language === 'tr' ? 'Ürün Kodu' : 'Product Code'}>
          <Controller
            name={`urunler[${index}].urunKodu`}
            control={control}
            render={({ field }) => <Input {...field} size="large" readOnly />}
          />
        </Form.Item>
      </div>

      <div style={{ flex: '1 1 auto', minWidth: '80px' }}>
        <Form.Item label={language === 'tr' ? 'Miktar' : 'Quantity'}>
          <Controller
            name={`urunler[${index}].miktar`}
            control={control}
            render={({ field }) => <Input {...field} size="large" onChange={(e) => {
              field.onChange(e);
              calculatePrices(index); // Tutarı güncelle
            }} />}
          />
        </Form.Item>
      </div>

      <div style={{ flex: '1 1 auto', minWidth: '80px' }}>
        <Form.Item label={language === 'tr' ? 'Birim' : 'Unit'}>
          <Controller
            name={`urunler[${index}].birim`}
            control={control}
            render={({ field }) => <Input {...field} size="large" readOnly />}
          />
        </Form.Item>
      </div>

      <div style={{ flex: '1 1 auto', minWidth: '100px' }}>
        <Form.Item label={language === 'tr' ? 'Birim Fiyat' : 'Unit Price'}>
          <Controller
            name={`urunler[${index}].birimFiyat`}
            control={control}
            render={({ field }) => <Input {...field} size="large" onChange={(e) => {
              field.onChange(e);
              calculatePrices(index); // Tutarı güncelle
            }} />}
          />
        </Form.Item>
      </div>

      <div style={{ flex: '1 1 auto', minWidth: '100px' }}>
        <Form.Item label={language === 'tr' ? 'Para Birimi' : 'Currency'}>
          <Controller
            name={`urunler[${index}].paraBirimi`}
            control={control}
            render={({ field }) => (
              <Select {...field} size="large" onChange={(value) => {
                field.onChange(value);
                calculatePrices(index); // Tutarı güncelle
              }}>
                <Option value="TRY">TRY</Option>
                <Option value="USD">USD</Option>
                <Option value="EUR">EUR</Option>
              </Select>
            )}
          />
        </Form.Item>
      </div>

      <div style={{ flex: '1 1 auto', minWidth: '80px' }}>
        <Form.Item label={`${language === 'tr' ? 'İsk' : 'Disc'} (%)`}>
          <Controller
            name={`urunler[${index}].iskonto`}
            control={control}
            render={({ field }) => <Input {...field} size="large" onChange={(e) => {
              field.onChange(e);
              calculatePrices(index); // Tutarı güncelle
            }} />}
          />
        </Form.Item>
      </div>

      <div style={{ flex: '1 1 auto', minWidth: '80px' }}>
        <Form.Item label={`${language === 'tr' ? 'KDV' : 'VAT'} (%)`}>
          <Controller
            name={`urunler[${index}].kdv`}
            control={control}
            render={({ field }) => <Input {...field} size="large" onChange={(e) => {
              field.onChange(e);
              calculatePrices(index); // Tutarı güncelle
            }} />}
          />
        </Form.Item>
      </div>

      <div style={{ flex: '1 1 auto', minWidth: '100px' }}>
        <Form.Item label={language === 'tr' ? 'Tutar' : 'Amount'}>
          <Controller
            name={`urunler[${index}].tutar`}
            control={control}
            render={({ field }) => <Input {...field} size="large" readOnly />}
          />
        </Form.Item>
      </div>

      <div style={{ flex: '1 1 auto', minWidth: '100px' }}>
        <Form.Item label={<span style={{ whiteSpace: 'nowrap' }}>{language === 'tr' ? 'Mevcut Stok' : 'Current Stock'}</span>}>
          <Controller
            name={`urunler[${index}].UnitAmount`}
            control={control}
            render={({ field }) => <Input {...field} size="large" readOnly />}
          />
        </Form.Item>
      </div>

      <div style={{ flex: '0  auto', minWidth: '80px', display: 'flex', alignItems: 'center' }}>
        <Button
          type="text"
          onClick={() => remove(index)}
          icon={<MinusCircleOutlined />}
          size="large"
          style={{ color: 'red' }}
        >
          {language === 'tr' ? 'Sil' : 'Delete'}
        </Button>
      </div>
    </div>
  ))}

  <Form.Item>
    <Button
      type="dashed"
      onClick={() => append({ urunAdi: '', urunKodu: '', urunKategorisi: '', miktar: '', birim: '', birimFiyat: '', paraBirimi: '', iskonto: '', kdv: '', tutar: '' })}
      block
      icon={<PlusOutlined />}
      size="large"
    >
      {language === 'tr' ? 'Ürün Ekle' : 'Add Product'}
    </Button>
  </Form.Item>
</Collapse.Panel>

            </Collapse>
            <div><br/></div>








            <Collapse defaultActiveKey={['3']} expandIconPosition="right">
              <Collapse.Panel header={language === 'tr' ? 'Sipariş Bilgileri' : 'Order Information'} key="3">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Sipariş Tarihi' : 'Order Date'} required>
                      <Controller
                        name="siparisTarihi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => (
                          <DatePicker onChange={(date) => field.onChange(date ? dayjs(date).toDate() : null)} {...field} format="DD-MM-YYYY" size="large" />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Geçerlilik Tarihi' : 'Validity Date'} required >
                      <Controller
                        name="sevkTarihi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => (
                          <DatePicker onChange={(date) => field.onChange(date ? dayjs(date).toDate() : null)} {...field} format="DD-MM-YYYY" size="large" />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Ödeme Şekli' : 'Payment Method'} required>
                      <Controller
                        name="odemeSekli"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            size="large"
                          >
                            {paymentMethods[language].map(method => (
                              <Option key={method.value} value={method.value}>{method.label}</Option>
                            ))}
                          </Select>
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Fatura Tipi' : 'Invoice Type'} required>
                      <Controller
                        name="faturaTipi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => (
        <Select {...field} size="large">
          {invoiceTypes[language].map(type => (
            <Option key={type.value} value={type.value}>{type.label}</Option>
          ))}
        </Select>
      )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Fatura Adresi' : 'Invoice Address'} required>
                      <Controller
                        name="faturaAdresi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Fatura Açıklamaları' : 'Invoice Notes'}>
                      <Controller
                        name="faturaAciklamalari"
                        control={control}
                        render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel header={language === 'tr' ? 'Teslimat Bilgileri' : 'Delivery Information'} key="4">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Teslimat Adresi' : 'Delivery Address'} required>
                      <Controller
                        name="teslimatAdresi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                 
                  <Col span={8}>
                  <Form.Item label={language === 'tr' ? 'Sevk Yöntemi' : 'Delivery Method'} required>
                  <Controller
  name="sevkYontemi"
  control={control}
  rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
  render={({ field }) => (
    <Select
      {...field}
      size="large"
      placeholder={language === 'tr' ? 'Sevk Yöntemi Seçin' : 'Select Delivery Method'}
      onChange={(value) => {
        const translatedValue = language === 'tr' ? translateValueToTurkish(value) : translateValueToEnglish(value);
        field.onChange(translatedValue);
      }}
      value={field.value} // Ensure the value is set correctly
    >
      {deliveryMethods[language].map(method => (
        <Option key={method.value} value={method.value}>{method.label}</Option>
      ))}
    </Select>
  )}
/>




              
                  </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'İstenen Sevk Adresi' : 'Desired Delivery Address'} required>
                      <Controller
                        name="istenenSevkAdresi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Nakliye Firması' : 'Shipping Company'}>
                      <Controller
                        name="nakliyeFirmasi"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Lojistik Ücreti' : 'Logistics Cost'} required>
                      <Controller
                        name="lojistikUcreti"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            size="large"
                          >
                            {logisticsCosts[language].map(cost => (
                              <Option key={cost.value} value={cost.value}>{cost.label}</Option>
                            ))}
                          </Select>
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label={language === 'tr' ? 'Teslimat Notları' : 'Delivery Notes'}>
                  <Controller
                    name="teslimatNotlari"
                    control={control}
                    render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                  />
                </Form.Item>
              </Collapse.Panel>
              <Collapse.Panel header={language === 'tr' ? 'Diğer Bilgiler' : 'Other Information'} key="5">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Belge No' : 'Document No'}>
                      <Controller
                        name="belgeNo"
                        control={control}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Belge Tarihi' : 'Document Date'}>
                      <Controller
                        name="belgeTarihi"
                        control={control}
                        render={({ field }) => (
                          <DatePicker onChange={(date) => field.onChange(date ? dayjs(date).toDate() : null)} {...field} format="DD-MM-YYYY" size="large" />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Özel Talimatlar' : 'Special Instructions'}>
                      <Controller
                        name="ozelTalimatlar"
                        control={control}
                        render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Sipariş Notları' : 'Order Notes'}>
                      <Controller
                        name="siparisNotlari"
                        control={control}
                        render={({ field }) => <TextArea rows={2} {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel header={language === 'tr' ? 'Satış Temsilcisi Bilgileri' : 'Sales Representative Information'} key="6">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Formu Hazırlayan Adı' : 'Prepared By'} required>
                      <Controller
                        name="formuHazirlayanAdi"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label={language === 'tr' ? 'Formu Hazırlayan Telefon' : 'Prepared By Phone'} required>
                      <Controller
                        name="formuHazirlayanTelefon"
                        control={control}
                        rules={{ required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required' }}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Formu Hazırlayan Email">
                      <Controller
                        name="formuHazirlayanEmail"
                        control={control}
                        rules={{
                          required: language === 'tr' ? 'Bu alan zorunludur' : 'This field is required',
                          pattern: { value: /^\S+@\S+$/i, message: language === 'tr' ? 'Geçerli bir email girin' : 'Enter a valid email' }
                        }}
                        render={({ field }) => <Input {...field} size="large" />}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
            <Form.Item>
            <div><br/></div>

              <Button type="primary" htmlType="submit" block size="large">
                {language === 'tr' ? 'Siparişi Kaydet' : 'Save Order'}
              </Button>
            </Form.Item>

            <div style={{ display: "none" }}>
            <PrintComponent
  language={language}
  ref={componentRef}
  title={getTitle()}
  customerInfo={{
    cariKodu: watch("cariKodu"),
    cariAdi: watch("cariAdi"),
    adres: watch("adres"),
    vergiDairesi: watch("vergiDairesi"),
    vergiNumarasi: watch("vergiNumarasi"),
    email: watch("email"),
    telefon: watch("telefon"),
    ilgiliKisiAdi: watch("ilgiliKisiAdi"),
    ilgiliKisiTelefon: watch("ilgiliKisiTelefon"),
    ilgiliKisiEmail: watch("ilgiliKisiEmail"),
  }}
  productInfo={watch("urunler")}
  orderInfo={{
    siparisTarihi: watch("siparisTarihi") ? new Date(watch("siparisTarihi")).toLocaleDateString("tr-TR") : "",
    odemeSekli: watch("odemeSekli"),
    faturaTipi: (() => {
      const type = invoiceTypes[language].find(t => t.value === watch("faturaTipi"));
      return type ? type.label : watch("faturaTipi");
    })(),
    faturaAdresi: watch("faturaAdresi"),
    faturaAciklamalari: watch("faturaAciklamalari"),
  }}
  deliveryInfo={{
    teslimatAdresi: watch("teslimatAdresi"),
    sevkTarihi: watch("sevkTarihi") ? new Date(watch("sevkTarihi")).toLocaleDateString("tr-TR") : "",
    sevkYontemi: [watch("sevkYontemi")].flat().map(value => {
      const method = deliveryMethods[language].find(m => m.value === value);
      return method ? method.label : value;
    }),
    istenenSevkAdresi: watch("istenenSevkAdresi"),
    nakliyeFirmasi: watch("nakliyeFirmasi"),
    lojistikUcreti: (() => {
      const type = logisticsCosts[language].find(t => t.value === watch("lojistikUcreti"));
      return type ? type.label : watch("lojistikUcreti");
    })(),
    belgeNo: watch("belgeNo"),
    belgeTarihi: watch("belgeTarihi") ? new Date(watch("belgeTarihi")).toLocaleDateString("tr-TR") : "",
  }}
  otherInfo={{
    ozelTalimatlar: watch("ozelTalimatlar"),
    siparisNotlari: watch("siparisNotlari"),
  }}
  salesRepInfo={{
    formuHazirlayanAdi: watch("formuHazirlayanAdi"),
    formuHazirlayanTelefon: watch("formuHazirlayanTelefon"),
    formuHazirlayanEmail: watch("formuHazirlayanEmail"),
  }}
  loading={loading}
/>


</div>


          </Form>
        </Card>
      </div>
    </div>
  );
};

export default PendingOrderForm;
