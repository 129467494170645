import React, { useState, useEffect } from "react";
import { Form, Input, Button, Table, Space, message, Card, Typography, Popconfirm, Row, Col } from "antd";
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../environment";
import Swal from "sweetalert2";

const { Title } = Typography;

const CariTumIslemler = () => {
  const [form] = Form.useForm();
  const [cariler, setCariler] = useState([]);
  const [editingId, setEditingId] = useState(null);

  const fetchCariler = async () => {
    const querySnapshot = await getDocs(collection(db, "allCaris"));
    const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setCariler(data);
  };

  const onFinish = async (values) => {
    if (editingId) {
      const docRef = doc(db, "allCaris", editingId);
      await updateDoc(docRef, values);
      message.success("Cari güncellendi!");
    } else {
      await addDoc(collection(db, "allCaris"), values);
      message.success("Yeni cari eklendi!");
    }
    form.resetFields();
    setEditingId(null);
    fetchCariler();
  };

  const onEdit = (record) => {
    form.setFieldsValue(record);
    setEditingId(record.id);
  };

  const onDelete = async (id) => {
    Swal.fire({
      title: 'Silmek istediğinize emin misiniz?',
      text: "Bu işlemi geri alamazsınız!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Evet, sil!',
      cancelButtonText: 'Hayır, iptal et'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteDoc(doc(db, "allCaris", id));
        message.success("Cari silindi!");
        fetchCariler();
        Swal.fire('Silindi!', 'Cari başarıyla silindi.', 'success');
      }
    });
  };

  useEffect(() => {
    fetchCariler();
  }, []);
  const addNew = () => {
    form.resetFields();
    setEditingId(null);
  };
  const columns = [
    { title: "Kart Kodu", dataIndex: "KartKodu", key: "KartKodu", align: "center" },
    { title: "Kart Adı", dataIndex: "KartAdi", key: "KartAdi", align: "center" },
    { title: "Adres", dataIndex: "Adres", key: "Adres", align: "center" },
    { title: "Alıcı/Satıcı", dataIndex: "AliciSatici", key: "AliciSatici", align: "center" },
    { title: "Muh. Hesap Adı", dataIndex: "MuhHesapAdi", key: "MuhHesapAdi", align: "center" },
    { title: "Muh. Hesap Kodu", dataIndex: "MuhHesapKodu", key: "MuhHesapKodu", align: "center" },
    { title: "TC Kimlik No", dataIndex: "TCKimlikNo", key: "TCKimlikNo", align: "center" },
    { title: "Vergi Dairesi", dataIndex: "VergiDairesi", key: "VergiDairesi", align: "center" },
    {
      title: "İşlemler",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record)}>
            Düzenle
          </Button>
          <Popconfirm
            title="Silmek istediğinize emin misiniz?"
            onConfirm={() => onDelete(record.id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button type="link" danger>
              Sil
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="page-wrapper" style={{ marginTop: "60px", padding: "30px", background: "#f0f2f5" }}>
      <Row gutter={16}>
        <Col span={5}>
          <Card style={{ borderRadius: "15px", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", padding: "20px" }}>
            <Title level={3} style={{ marginBottom: "20px", textAlign: "center", color: "#1890ff" }}>
              Cari İşlemleri Yönetimi
            </Title>
            <Button onClick={()=>addNew()}>Yeni Cari Ekle</Button>
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Form.Item name="KartAdi" label="Kart Adı" rules={[{ required: true, message: "Kart Adı giriniz!" }]}>
                <Input placeholder="Kart Adı" />
              </Form.Item>
              <Form.Item name="KartKodu" label="Kart Kodu" rules={[{ required: true, message: "Kart Kodu giriniz!" }]}>
                <Input placeholder="Kart Kodu" />
              </Form.Item>
              <Form.Item name="AliciSatici" label="Alıcı/Satıcı">
                <Input placeholder="Alıcı/Satıcı" />
              </Form.Item>
              <Form.Item name="Adres" label="Adres">
                <Input placeholder="Adres" />
              </Form.Item>
              <Form.Item name="MuhHesapAdi" label="Muh. Hesap Adı">
                <Input placeholder="Muh. Hesap Adı" />
              </Form.Item>
              <Form.Item name="MuhHesapKodu" label="Muh. Hesap Kodu">
                <Input placeholder="Muh. Hesap Kodu" />
              </Form.Item>
              <Form.Item name="TCKimlikNo" label="TC Kimlik No">
                <Input type="number" placeholder="TC Kimlik No" />
              </Form.Item>
              <Form.Item name="VergiDairesi" label="Vergi Dairesi">
                <Input placeholder="Vergi Dairesi" />
              </Form.Item>
              <Form.Item style={{ textAlign: "center", marginTop: "20px" }}>
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                  {editingId ? "Güncelle" : "Ekle"}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={19}>
          <Card style={{ borderRadius: "15px", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}>
            <Table columns={columns} dataSource={cariler} rowKey="id" pagination={{ pageSize: 5 }} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CariTumIslemler;
