import React, { useState, useEffect, useRef } from 'react';
import { Form, Select, Button, DatePicker, Row, Col, Spin, Input, Table, Modal, message } from 'antd';
import { collection, getDocs, query, where, addDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../environment';
import { useReactToPrint } from 'react-to-print';
import yucellogo from '../../yclkimya.png';

const { Option } = Select;

const GirisIrsaliyesiForm = () => {
  const [depolar, setDepolar] = useState([]);
  const [plasiyerler, setPlasiyerler] = useState([]);
  const [cariler, setCariler] = useState([]);
  const [stoklar, setStoklar] = useState([]);
  const [selectedDepo, setSelectedDepo] = useState(null);
  const [selectedStok, setSelectedStok] = useState(null);
  const [selectedStokUnit, setSelectedStokUnit] = useState('');
  const [miktar, setMiktar] = useState('');
  const [selectedCari, setSelectedCari] = useState(null);
  const [selectedPlasiyer, setSelectedPlasiyer] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [selectedOdemePlani, setSelectedOdemePlani] = useState('PEŞİN');
  const [cekDetay, setCekDetay] = useState('');
  const [evrakTarihi, setEvrakTarihi] = useState(null);
  const [stokMiktari, setStokMiktari] = useState(''); 
  const printRef = useRef();

  // Depoları Firebase'den çek
  const fetchDepolar = async () => {
    const depoSnapshot = await getDocs(collection(db, 'depolar'));
    const depoList = depoSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setDepolar(depoList);
  };

  // Plasiyerleri Firebase'den çek
  const fetchPlasiyerler = async () => {
    const plasiyerSnapshot = await getDocs(collection(db, 'users'));
    const plasiyerList = plasiyerSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setPlasiyerler(plasiyerList);
  };

  // Carileri Firebase'den çek
  const fetchCariler = async () => {
    const cariSnapshot = await getDocs(collection(db, 'allCaris'));
    const cariList = cariSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCariler(cariList);
  };

  // Seçilen depoya göre stokları Firebase'den çek
  const fetchStoklarByDepo = async (depoId) => {
    const stokQuery = query(collection(db, 'stocks'), where('Warehouse', '==', depoId));
    const stokSnapshot = await getDocs(stokQuery);
    const stokList = stokSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setStoklar(stokList);
  };

  // Depo seçildiğinde stokları filtrele
  const handleDepoChange = (value) => {
    setSelectedDepo(value);
    fetchStoklarByDepo(value);
  };

  // Stok seçildiğinde, ilgili birim (Unit) ve mevcut miktarı set et
  const handleStokChange = (value) => {
    const stok = stoklar.find(stok => stok.StockName === value);
    if (stok) {
      setSelectedStok(stok);
      setSelectedStokUnit(stok.Unit);
      setStokMiktari(stok.UnitAmount); // Set the initial amount for the stock
    }
  };
  

  // Cari seçildiğinde
  const handleCariChange = (value) => {
    setSelectedCari(value);
  };

  // Plasiyer seçildiğinde
  const handlePlasiyerChange = (value) => {
    setSelectedPlasiyer(value);
  };

  // Evrak tarihi seçildiğinde
  const handleEvrakTarihiChange = (date) => {
    setEvrakTarihi(date.format("DD/MM/YYYY"));
  };

  // Miktar ekleme işlemi
  const handleAdd = () => {
    if (!selectedStok || !miktar || !selectedCari || !selectedPlasiyer) {
      message.error("Lütfen tüm alanları doldurun.");
      return;
    }
  
    const newData = {
      key: selectedStok.id,
      stokAdi: selectedStok.StockName,
      miktar: miktar,
      birim: selectedStokUnit,
      cari: selectedCari,
    };
  
    setTableData([...tableData, newData]);
  
    // Reset selections
    
  };
  

  // Edit and Delete Actions
  const handleEdit = (record) => {
    setSelectedStok(record.stokAdi);
    setMiktar(record.miktar);
    setSelectedCari(record.cari);
  };

  const handleDelete = (key) => {
    setTableData(tableData.filter((item) => item.key !== key));
    message.success('Stok başarıyla silindi');
  };

  // PDF oluşturma ve gösterme
  const handleCreateIrsaliye = () => {
    setIsModalVisible(true);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: async () => {
      setIsModalVisible(false);
      setIsConfirmationVisible(true);
    },
  });

  const handleModalOk = () => {
    handlePrint();
  };

  // UnitAmount'a miktar ekleme işlemi

  
  
  
  

  
  
  const handleConfirmationOk = async () => {
    if (!tableData.length) {
      message.error("Tabloda güncellenecek stok yok.");
      return;
    }
  
    try {
      // Add the new Irsaliye to Firebase
      await addDoc(collection(db, 'girisIrsaliyeler'), {
        tableData,
        odemePlani: selectedOdemePlani,
        cekDetay,
        depo: selectedDepo,
        evrakTarihi,
        plasiyer: selectedPlasiyer,
      });
  
      // Loop through tableData and update the stock quantity for each item
      await Promise.all(
        tableData.map(async (item) => {
          const stokRef = doc(db, 'stocks', item.key); // Assuming the key in tableData is the stock's document ID
  
          // Fetch the current stock data using getDoc for individual documents
          const stokSnapshot = await getDoc(stokRef);
          const stokData = stokSnapshot.data(); // Directly get the stock data from the document
  
          if (stokData) {
            const existingAmount = parseFloat(stokData.UnitAmount) || 0; // Get current amount in Firebase
            const addedAmount = parseFloat(item.miktar) || 0; // Get amount from the tableData
  
            // Update the stock quantity in Firebase
            await updateDoc(stokRef, {
              UnitAmount: existingAmount + addedAmount,
            });
  
            console.log(`Updated stock ${item.key}: ${existingAmount} + ${addedAmount}`);
          } else {
            console.error(`Stock not found for ID: ${item.key}`);
          }
        })
      );
  
      // Clear the form fields and reset state variables
      setTableData([]);
      setMiktar('');
      setSelectedStok(null); // Clear selected stock
      setSelectedStokUnit('');
      setSelectedCari(null);
      setSelectedPlasiyer(null);
      setSelectedDepo(null);
      setEvrakTarihi(null);
      setSelectedOdemePlani('PEŞİN');
      setCekDetay('');
      setIsConfirmationVisible(false);
  
      message.success('İrsaliye başarıyla kaydedildi ve stok miktarları güncellendi.');
    } catch (error) {
      console.error('Error during confirmation:', error);
      message.error('İrsaliye kaydedilirken hata oluştu.');
    }
  };
  
  
  
  
  
  

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleConfirmationCancel = () => {
    setIsConfirmationVisible(false);
  };

  // Çek seçildiğinde ekstra detayları al
  const handleOdemePlaniChange = (value) => {
    setSelectedOdemePlani(value);
    if (value === 'ÇEK') {
      setCekDetay('');
    } else {
      setCekDetay('');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchDepolar();
      await fetchPlasiyerler();
      await fetchCariler();
      setLoading(false);
    };
    fetchData();
  }, []);

  // Table Columns with Edit and Delete Buttons
  const columns = [
    {
      title: 'Stok Adı',
      dataIndex: 'stokAdi',
      key: 'stokAdi',
    },
    {
      title: 'Miktar',
      dataIndex: 'miktar',
      key: 'miktar',
    },
    {
      title: 'Birim',
      dataIndex: 'birim',
      key: 'birim',
    },
    {
      title: 'Cari',
      dataIndex: 'cari',
      key: 'cari',
    },
    {
      title: 'Düzenle',
      key: 'edit',
      render: (_, record) => (
        <Button onClick={() => handleEdit(record)}>Düzenle</Button>
      ),
    },
    {
      title: 'Sil',
      key: 'delete',
      render: (_, record) => (
        <Button type="danger" onClick={() => handleDelete(record.key)}>
          Sil
        </Button>
      ),
    },
  ];

  // Columns without Edit and Delete for PDF
  const pdfColumns = columns.filter(col => col.key !== 'edit' && col.key !== 'delete');

  if (loading) {
    return <Spin size="large" className="loading-spinner" />;
  }

  return (
    <div className='page-wrapper mt-5'>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Evrak Tipi">
              <Select defaultValue="Toptan">
                <Option value="Toptan">Toptan</Option>
                <Option value="Perakende">Perakende</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Evrak Tarihi">
              <DatePicker format={"DD/MM/YYYY"} onChange={handleEvrakTarihiChange} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Ödeme Planı">
              <Select defaultValue="PEŞİN" onChange={handleOdemePlaniChange}>
                <Option value="PEŞİN">Peşin</Option>
                <Option value="VADELİ">Vadeli</Option>
                <Option value="ÇEK">Çek</Option>
              </Select>
              {selectedOdemePlani === 'ÇEK' && (
                <Input
                  placeholder="Çek Detayları Girin"
                  value={cekDetay}
                  onChange={(e) => setCekDetay(e.target.value)}
                  style={{ marginTop: '10px' }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Plasiyer">
              <Select
                showSearch
                placeholder="Plasiyer seç"
                optionFilterProp="children"
                onChange={handlePlasiyerChange}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {plasiyerler.map(plasiyer => (
                  <Option key={plasiyer.id} value={plasiyer.username}>
                    {plasiyer.username}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Depo">
              <Select
                showSearch
                placeholder="Depo seç"
                optionFilterProp="children"
                onChange={handleDepoChange}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {depolar.map(depo => (
                  <Option key={depo.id} value={depo.id}>
                    {depo.depoAdi}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Cari">
              <Select
                showSearch
                placeholder="Cari seç"
                optionFilterProp="children"
                onChange={handleCariChange}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {cariler.map(cari => (
                  <Option key={cari.id} value={cari.KartAdi}>
                    {cari.KartAdi}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
          <Form.Item label="Stok">
  <Select
    showSearch
    placeholder="Stok seç"
    optionFilterProp="children"
    onChange={handleStokChange}
    disabled={!selectedDepo}
    filterOption={(input, option) =>
      option?.children.toLowerCase().includes(input.toLowerCase())
    }
  >
    {stoklar.map(stok => (
      <Option key={stok.id} value={stok.StockName}>
        {stok.StockName}
      </Option>
    ))}
  </Select>
  {selectedStok && (
    <p>Mevcut Miktar: {stokMiktari} {selectedStokUnit}</p> 
  )}
</Form.Item>

          </Col>
          <Col span={8}>
            <Form.Item label="Miktar">
              <Input
                placeholder={`Miktar (${selectedStokUnit})`}
                value={miktar}
                onChange={(e) => setMiktar(e.target.value)}
                disabled={!selectedStok}
              />
            </Form.Item>
          </Col>
        </Row>

        <Button type="primary" onClick={handleAdd} disabled={!miktar || !selectedStok || !selectedCari}>
          Stok Ekle
        </Button>

        <Table
          columns={columns}
          dataSource={tableData}
          style={{ marginTop: '20px' }}
        />

        <Row gutter={16} style={{ marginTop: '20px' }}>
          <Button type="primary" block onClick={handleCreateIrsaliye}>
            İrsaliye Oluştur
          </Button>
        </Row>
      </Form>

      {/* PDF Görüntüsü */}
      <Modal
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        title="İrsaliye Onayı"
        width={1250} // Büyütülmüş modal boyutu
      >
        <div ref={printRef} style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '10px', backgroundColor: '#f8f9fa' }}>
          <img src={yucellogo} alt="Logo" style={{ maxWidth: '110px', marginBottom: '10px' }} />
          <h2 style={{ textAlign: 'center', fontWeight: 'bold', color: '#d9534f' }}>İrsaliye Belgesi</h2>
          <p><strong>Evrak Tarihi:</strong> {evrakTarihi}</p>
          <p><strong>Plasiyer:</strong> {selectedPlasiyer}</p>
          <p><strong>Ödeme Planı:</strong> {selectedOdemePlani} {selectedOdemePlani === 'ÇEK' && `(Detay: ${cekDetay})`}</p>
          <h3 style={{ color: '#d9534f' }}>Stok Bilgileri</h3>
          <Table
            columns={pdfColumns} // Düzenle ve Sil sütunları PDF'de yok
            dataSource={tableData}
            pagination={false}
            style={{ marginTop: '20px' }}
          />
        </div>
      </Modal>

      {/* Onay Modalı */}
      <Modal
        visible={isConfirmationVisible}
        onOk={handleConfirmationOk}
        onCancel={handleConfirmationCancel}
        title="Onay"
      >
        <p>İrsaliyeyi kaydetmek istediğinize emin misiniz?</p>
      </Modal>
    </div>
  );
};

export default GirisIrsaliyesiForm;
